import { customAlphabet } from "nanoid";
import { ApiQuoteProductItem } from "../api/types";
import { QuoteLine } from "../quotes/add-edit/types";
import { KPILabelMappings } from "./constants";

const nanoid = customAlphabet("1234567890abcdef", 10);

export const createId = () => nanoid();

export const withPayloadType = <T>() => (t: T) => ({ payload: t });


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const percentWholeNumberFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const formatCurrency = (input: number) => { console.log('input', input); return formatter.format(input); }
export const formatDecimal = (input: number) => decimalFormatter.format(input);
export const formatPercent = (input: number) => percentFormatter.format(input > 1 ? input * 0.01 : input);
export const formatWholeNumberPercent = (input: number | string) => {
  if(typeof input === 'string')
    input = Number(input?.toString().replace('%',''));

  return percentWholeNumberFormatter.format(input > 1 ? input * 0.01 : input);
}

export const calculateDrivenPrice = (products: ApiQuoteProductItem[],
  quoteLine: Partial<QuoteLine>) => {
    const currentProduct: ApiQuoteProductItem | undefined = products.find(x => x.sku === quoteLine.partNum);
  
    const drivenDiscount = isNaN(Number(quoteLine.drivenSecurityMSRPDiscount?.replace('%',''))) ? 0 : Number(quoteLine.drivenSecurityMSRPDiscount?.replace('%','')) * 0.01;
    const drivenPrice = currentProduct!.msrp - (currentProduct!.msrp * drivenDiscount);
    return Number(drivenPrice.toFixed(2));
}

export const calculateCustomerPrice = (products: ApiQuoteProductItem[],
  quoteLine: Partial<QuoteLine>) => {
    const currentProduct: ApiQuoteProductItem | undefined = products.find(x => x.sku === quoteLine.partNum);
  
    const customerDiscount = isNaN(Number(quoteLine.customerMSRPDiscount?.replace('%',''))) ? 0 : Number(quoteLine.customerMSRPDiscount?.replace('%','')) * 0.01;
    const customerPrice = currentProduct!.msrp - (currentProduct!.msrp * customerDiscount);
    return Number(customerPrice.toFixed(2));
}

export const calculateProductPrice = (products: ApiQuoteProductItem[],
  quoteLine: Partial<QuoteLine>) => {
    const currentProduct: ApiQuoteProductItem | undefined = products.find(x => x.sku === quoteLine.partNum);
  
    const customerDiscount = isNaN(Number(quoteLine.customerMSRPDiscount?.replace('%',''))) ? 0 : Number(quoteLine.customerMSRPDiscount?.replace('%','')) * 0.01;
    const basePrice = !currentProduct?.zeroMarginProduct ? currentProduct!.msrp - (currentProduct!.msrp * customerDiscount) : currentProduct!.msrp;
    return Number((basePrice * (quoteLine.qty ?? 1)).toFixed(2));
}

export const calculateProductMSRPPrice = (products: ApiQuoteProductItem[], quoteLine: Partial<QuoteLine>) => {
  const currentProduct: ApiQuoteProductItem | undefined = products.find(x => x.sku === quoteLine.partNum);

  const basePrice = currentProduct!.msrp;
  return Number(basePrice.toFixed(2));
}

export const calculateMarginCustomer = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[]): number => {
  const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const productLines = [...lines.slice(0, lines.length - 1)].filter(l => marginExemptProducts.find(x => x.sku === l.partNum) === undefined);
  if(productLines.length === 0)
    return 0;

  const margin = productLines.reduce(
    (sum, line) => {
      const customerMargin = isNaN(Number(line.customerMSRPDiscount?.replace('%',''))) ? 0 : Number(line.customerMSRPDiscount?.replace('%',''));
      return sum + customerMargin;
    }, 0);
  
  return Number((margin / productLines.length).toFixed(2));
}

export const calculateMarginDriven = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[]): number => {
  const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const productLines = [...lines.slice(0, lines.length - 1)].filter(l => marginExemptProducts.find(x => x.sku === l.partNum) === undefined);
  if(productLines.length === 0)
    return 0;

  const margin = productLines.reduce(
    (sum, line) => {
      const customerMargin = isNaN(Number(line.drivenSecurityMSRPDiscount?.replace('%',''))) ? 0 : Number(line.drivenSecurityMSRPDiscount?.replace('%',''));
      return sum + customerMargin;
    }, 0);
  
    return Number((margin / productLines.length).toFixed(2));
}

export const calculateMargin = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[]): number => {
  const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const productLines = lines.filter(l => l.partNum !== undefined && l.partNum !== null && l.partNum !== '' && marginExemptProducts.find(x => x.sku === l.partNum) === undefined);
  if(productLines.length === 0)
    return 0;

  const marginableSubtotal = lines.filter(l => l.partNum !== undefined && marginExemptProducts.find(x => x.sku === l.partNum) === undefined).reduce(
    (sum, x) => {
      return (sum + ((x.qty ?? 0) * (x.msrp ?? x.price! )));
    }
    ,0.00);

  const subtotal = marginableSubtotal;
  const marginDollars = calculateMarginDollars(productLines, products);
  const margin = ((marginDollars / subtotal) * 100);
  return Number(margin);
}

export const calculateMarginDollarsLegacy = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[], customerDiscountMSRP?: string | number, drivenDiscountMSRP?: string | number): number => {
  let customerDiscount: number = Number(customerDiscountMSRP?.toString().replace('%',''));
  let drivenDiscount: number = Number(drivenDiscountMSRP?.toString().replace('%',''));

  if(isNaN(customerDiscount) || isNaN(drivenDiscount))
    customerDiscount = 0

  if(drivenDiscount === 0 || customerDiscount === 0)
    drivenDiscount = 0;

    const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const marginableSubtotal = ([...lines.slice(0, lines.length - 1)].filter(l => marginExemptProducts.find(x => x.sku === l.partNum) === undefined).reduce(
    (sum, x) => {
      return (sum + ((x.qty ?? 0) * (x.msrp ?? x.price! )));
    }
    ,0.00));

    const marginExemptSubtotal = ([...lines.slice(0, lines.length - 1)].filter(l => marginExemptProducts.find(x => x.sku === l.partNum) !== undefined).reduce(
    (sum, x) => {
      return (sum + ((x.qty ?? 0) * (x.msrp ?? x.price! )));
    }
    ,0.00));

  const drivenDiscountDollars = marginExemptSubtotal + (marginableSubtotal * (drivenDiscount / 100));
  const customerDiscountDollars = marginExemptSubtotal + (marginableSubtotal * (customerDiscount / 100));

  const marginDollars = drivenDiscountDollars - customerDiscountDollars;
  return Number(marginDollars.toFixed(2));
}

export const calculateMarginDollars = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[]): number => {
  // let customerDiscount: number = Number(customerDiscountMSRP?.toString().replace('%',''));
  // let drivenDiscount: number = Number(drivenDiscountMSRP?.toString().replace('%',''));

  // if(isNaN(customerDiscount) || isNaN(drivenDiscount))
  //   customerDiscount = 0

  // if(drivenDiscount === 0 || customerDiscount === 0)
  //   drivenDiscount = 0;

  const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const marginLines = lines.filter(l => l.partNum !== undefined && l.partNum !== null && l.partNum !== '');
  const marginableSubtotal = marginLines.reduce(
    (sum, line) => {
      const drivenDiscount = !isNaN(Number(line.drivenSecurityMSRPDiscount?.toString().replace('%',''))) ? Number(line.drivenSecurityMSRPDiscount?.toString().replace('%','')) * 0.01 : 0;
      const customerDiscount = !isNaN(Number(line.customerMSRPDiscount?.toString().replace('%',''))) ? Number(line.customerMSRPDiscount?.toString().replace('%','')) * 0.01 : 0;
      
      const price = line.msrp ?? line.price!;
      
      return ({
        driven: sum.driven + (line.qty ?? 0) * (price - (price * drivenDiscount)),
        customer: sum.customer + (line.qty ?? 0) * (price - (price * customerDiscount)),
      });
    }
      ,{driven: 0.00, customer: 0.00 }
    );

  const marginDollars = marginableSubtotal.customer - marginableSubtotal.driven;
  return Number(marginDollars.toFixed(2));
}

export const calculateSubtotal = (lines: Partial<QuoteLine>[], products: ApiQuoteProductItem[]): number => {
  const marginExemptProducts = products.filter(p => p.zeroMarginProduct === true);

  const marginableSubtotal = lines.filter(l => l.partNum !== undefined && marginExemptProducts.find(x => x.sku === l.partNum) === undefined).reduce(
    (sum, x) => {
      const customerDiscount = !isNaN(Number(x.customerMSRPDiscount?.toString().replace('%',''))) ? Number(x.customerMSRPDiscount?.toString().replace('%','')) * 0.01 : 0;
      const price = x.msrp ?? x.price!;
      return (sum + ((x.qty ?? 0) * (x.msrp === undefined ? x.price! - (x.price! * customerDiscount) : x.price! )));
    }
    ,0.00);

    const marginExemptSubtotal = lines.filter(l => marginExemptProducts.find(x => x.sku === l.partNum) !== undefined).reduce(
    (sum, x) => {
      return (sum + ((x.qty ?? 0) * (x.msrp ?? x.price! )));
    }
    ,0.00);
    
  const retSubtotal = marginableSubtotal;

  return Number(retSubtotal.toFixed(2));
}

export const mapStatusEnumToString = (status?: string) => {
  if (status === '1')
      return "Pending";
  
  if (status === '2')
      return 'Approved';

  if (status === '3')
      return 'Order'

  if (status === '4')
      return 'Rejected';

  if (status === '5')
      return 'Cancelled';

  return status;
}

export const mapStatusStringToEnum = (val?: string | number) => {
  const valStr = (val ?? "").toString();
  if(valStr === "Pending") return "1";
  if(valStr === "Approved") return "2";
  if(valStr === "Order") return "3";
  if(valStr === "Rejected") return "4";
  if(valStr === "Cancelled") return "5";

  return valStr;
}

export const isViewOnly = (val?: string) => {
  if(["2", "3", "4", "5"].some(x => x === val)) return true;

  return false;
}

export const mapKPILabel = (label: string) => {
  if(KPILabelMappings[label as keyof typeof KPILabelMappings] !== undefined)
    return KPILabelMappings[label as keyof typeof KPILabelMappings];

    return label;
}

export const formatKPIValue = (label: string, value: string) => {
  if(KPILabelMappings[label as keyof typeof KPILabelMappings] === undefined)
    return value;

    if(label.toLowerCase().indexOf("dollar") > -1)
      return formatCurrency(Number(value));

    if(label.toLowerCase().indexOf("avg") > -1)
      return formatPercent(Number(value) / 100.00)

    return value;
}