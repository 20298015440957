import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { GlobalStyles } from "./features/shared/styles/GlobalStyles";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AppTheme } from "./features/shared/styles/Theme";
import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { Toaster } from 'react-hot-toast';
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from './features/shared/store';
import { loadApp, selectSharedIsBusy } from "./features/shared/slice";
import { ConfirmResetPasswordPage, DashboardRoute, QuoteCatalogRoute, QuoteCreateRoute, QuoteEditRoute, QuoteSearchGridRoute, ResetPasswordPage, SettingsRoute, SignInRoute, UsersCreateRoute, UsersEditRoute, UsersSearchGridRoute } from "./Routes";
import { RegisterPage } from "./features/auth/pages/Register";

const AppWrapper = styled(Box)`
  background: url("/images/driven-background.svg") no-repeat;
  background-size: cover;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const AppInner = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export const App: React.FC<{}> = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <AppWrapper>
          <AppInner>
            <AppContent />
          </AppInner>
        </AppWrapper>
        <GlobalStyles />
      </ThemeProvider>
    </Provider>
  );
};

export const AppContent: React.FC<{}> = (props) => {
  let dispatch = useDispatch();
  let isAppBusy = useSelector(selectSharedIsBusy);

  useEffect(() => {
    dispatch(loadApp());
  }, [dispatch]);

  if(isAppBusy)
    return <>Loading...</>;

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/resetpassword/:userId/change/:token" element={<ConfirmResetPasswordPage />} />
        <Route path="/signin" element={<SignInRoute />} />
        <Route path="/register" element={<RegisterPage /> } />
        <Route path="/" index element={<DashboardRoute />} />
        <Route path="/users" element={<UsersSearchGridRoute />} />
        <Route path="/users/create" element={<UsersCreateRoute />} />
        <Route path="/users/edit/:id" element={<UsersEditRoute />} />
        <Route path="/quotes/create" element={<QuoteCreateRoute />} />
        <Route path="/quotes/edit/:id" element={<QuoteEditRoute />} />
        <Route path="/quotes/catalog" element={<QuoteCatalogRoute />} />
        <Route path="/quotes/search" element={<QuoteSearchGridRoute />} />
        <Route path="/settings" element={<SettingsRoute />} />
      </Routes>
    </BrowserRouter>
    <Toaster />
    </>
  );
};

