import loadable from '@loadable/component';
import React from 'react';

export const DashboardPage = loadable(() => import('./features/dashboard/pages/DashboardPage'), { resolveComponent: (components) => components.DashboardPage });
export const UsersGridPage = loadable(() => import('./features/users/search/pages/UsersGridPage'), { resolveComponent: (components) => components.UsersGridPage });
export const UsersCreatePage = loadable(() => import('./features/users/add-edit/pages/UserCreatePage'), { resolveComponent: components => components.UserCreatePage });
export const UsersEditPage = loadable(() => import('./features/users/add-edit/pages/UserEditPage'), { resolveComponent: components => components.UserCreatePage });
export const SettingsPage = loadable(() => import('./features/settings/pages/SettingsPage'), { resolveComponent: (components) => components.SettingsPage });
export const QuoteCreatePage = loadable(() => import('./features/quotes/add-edit/pages/QuoteCreatePage'), { resolveComponent: (components) => components.QuoteCreatePage });
export const QuoteEditPage = loadable(() => import('./features/quotes/add-edit/pages/QuoteEditPage'), { resolveComponent: (components) => components.QuoteEditPage });
export const QuoteCatalogPage = loadable(() => import('./features/quotes/catalog/pages/QuoteCatalogPage'), { resolveComponent: (components) => components.QuoteCatalogPage });
export const QuoteSearchGridPage = loadable(() => import('./features/quotes/search/pages/GridPage'), { resolveComponent: (components) => components.QuoteSearchGridPage });
export const SignInPage = loadable(() => import('./features/auth/pages/SignIn'), { resolveComponent: (components) => components.SignInPage });
export const RegistrationPage = loadable(() => import('./features/auth/pages/Register'), { resolveComponent: (components) => components.RegisterPage });
export const ResetPasswordPage = loadable(() => import('./features/auth/pages/ResetPasswordRequest'), { resolveComponent: components => components.ResetPasswordRequest });
export const ConfirmResetPasswordPage = loadable(() => import('./features/auth/pages/ResetPasswordConfirm'), { resolveComponent: component => component.ResetPassword});
export const AnoynmousLayout = loadable(() => import('./features/shared/components/AnoynmousLayout'), { resolveComponent: (components) => components.AnoynmousLayout });
export const SideNavLayout = loadable(() => import('./features/shared/components/SideNavLayout'), { resolveComponent: (components) => components.SideNavLayout });
export const ProtectedRoute = loadable(() => import('./features/auth/components/ProtectedRoute'), { resolveComponent: (components) => components.ProtectedRoute });

export const DashboardRoute = () => (
    <ProtectedRoute>
        <SideNavLayout>
            <DashboardPage />
        </SideNavLayout>
    </ProtectedRoute>
);

export const UsersSearchGridRoute = () => (
    <ProtectedRoute>
        <SideNavLayout>
            <UsersGridPage />
        </SideNavLayout>
    </ProtectedRoute>
);

export const UsersCreateRoute = () => (
    <ProtectedRoute>
        <SideNavLayout>
            <UsersCreatePage />
        </SideNavLayout>
    </ProtectedRoute>
)

export const UsersEditRoute = () => (
    <ProtectedRoute>
        <SideNavLayout>
            <UsersEditPage />
        </SideNavLayout>
    </ProtectedRoute>
)

export const SettingsRoute = () => (
    <ProtectedRoute>
        <SideNavLayout>
            <SettingsPage />
        </SideNavLayout>
    </ProtectedRoute>   
);

export const SignInRoute = () => (
    <AnoynmousLayout>
      <SignInPage />
    </AnoynmousLayout>
);

export const ResetPasswordRoute = () => (
    <ResetPasswordPage />
)

export const ConfirmResetPasswordRoute = () => (
    <ConfirmResetPasswordPage />
)

export const QuoteCreateRoute = () => (
    <ProtectedRoute>
      <SideNavLayout>
        <QuoteCreatePage />
      </SideNavLayout>
    </ProtectedRoute>
);
  
export const QuoteEditRoute = () => (
<ProtectedRoute>
    <SideNavLayout>
    <QuoteEditPage />
    </SideNavLayout>
</ProtectedRoute>
);

export const QuoteCatalogRoute = () => (
<ProtectedRoute>
    <SideNavLayout>
    <QuoteCatalogPage />
    </SideNavLayout>
</ProtectedRoute> 
);

export const QuoteSearchGridRoute = () => (
<ProtectedRoute>
    <SideNavLayout>
    <QuoteSearchGridPage />
    </SideNavLayout>
</ProtectedRoute>
);

export const RegistrationRoute = () => (
    <RegistrationPage />
)