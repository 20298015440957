import { createSlice, createAction, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { ReduxRootState } from "../../shared/store";
import { ReduxSliceState } from "../../shared/types";
import { withPayloadType } from "../../shared/utils";
import { AddFromCatalogProps, CreateQuoteProps, FormMode, LoadFeatureProps, QuoteFormState, ReduxAddEditQuoteState, ResetStateProps, ShowAfterActionProps, UpdateQuoteProps } from "./types";

const initialState: ReduxAddEditQuoteState = {
    state: 'BUSY',
    mode: undefined,
    formState: undefined,
    afterActionState: {
        open: false,
        quoteId: undefined,
        pdfUrl: undefined,
    },
};

export const resetState = createAction('quotes/add-edit/resetState', withPayloadType<ResetStateProps>());
export const loadFeature = createAction('quotes/add-edit/loadFeature', withPayloadType<LoadFeatureProps>());
export const loadQuote = createAction('quote/add-edit/loadQuote', withPayloadType<string>());
export const loadProducts = createAction('loadProducts', withPayloadType<void>());
export const addFromCatalog = createAction('addFromCatalog', withPayloadType<AddFromCatalogProps>());
export const createQuote = createAction('quotes/add-edit/createQuote', withPayloadType<CreateQuoteProps>());
export const updateQuote = createAction('quote/add-edit/updateQuote', withPayloadType<UpdateQuoteProps>());

const quoteAddEditSlice = createSlice({
    name: 'quoteAddEdit',
    initialState,
    reducers: {
        resetToInitialState: () => initialState,
        setMode: (state, action: PayloadAction<FormMode>) => {
            state.mode = action.payload;
        },
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setFormState: (state, action: PayloadAction<Partial<QuoteFormState>>) => {
            state.formState = action.payload;
            state.state = 'READY';
        },
        showAfterAction: (state, action: PayloadAction<ShowAfterActionProps>) => {
            const { payload: { open, quoteId, pdfUrl } } = action;
            state.afterActionState = {
                open,
                quoteId,
                pdfUrl,
            };
        },
    }
});

const selectSelf = (state: ReduxRootState) => state.quoteAddEdit;
export const isBusy = createSelector(selectSelf, self => self.state === 'BUSY');
export const selectAfterActionState = createSelector(selectSelf, self => self.afterActionState);
export const selectQuoteFormstate = createSelector(selectSelf, self => self.formState);
export const selectMode = createSelector(selectSelf, self => self.mode);
export const selectIsAddMode = createSelector(selectSelf, self => self.mode === 'add');
export const selectIsEditMode = createSelector(selectSelf, self => self.mode === 'edit');
export default quoteAddEditSlice;
