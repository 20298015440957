import React, { useCallback } from 'react';
import { RegisterFormState } from '../types';
import * as yup from 'yup';
import { Box, Button, Container, Grid, styled } from '@mui/material';
import { createForm, SelectField, TextField } from '../../shared/components/form';
import { AnoynmousLayout } from '../../shared/components/AnoynmousLayout';
import { PageSection } from '../../shared/components/PageSection';
import { STATES } from '../../shared/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { register } from '../slice';


const formSchema = yup
    .object()
    .shape<Record<keyof RegisterFormState, yup.AnySchema>>({
        username: yup.string().email().required("Email is required."),
        password: yup.string().required("Password is required."),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], "Confirm Password does not match")
            .required("Confirm Password is required"),
        name: yup.string().required("Name is required"),
        phone: yup.string().optional(),
        address1: yup.string().optional(),
        address2: yup.string().optional(),
        city: yup.string().optional(),
        state: yup.string().optional(),
        zipCode: yup.string().optional(),
    })
    .required();

const getDefaultValues = (): RegisterFormState => ({
    username: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
});

export type RegisterPageProps = {

}

export const RegisterPage: React.FC<RegisterPageProps> = (props) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const handleSubmitSuccess = useCallback((data: RegisterFormState, e?: React.BaseSyntheticEvent) => {
        dispatch(register({
            navigate,
            ...data,
        }));
    }, [dispatch, navigate]);

    const handleSubmitError = useCallback((errors: any, e?: React.BaseSyntheticEvent) => {
        const ref = Object.entries(errors).map(x => x as any)[0];
        if(ref === undefined)
            return;
        
        document.getElementById(ref[0])?.focus();
    }, []);

    let formRef = React.useRef<HTMLFormElement>(null);
    const Form = createForm<RegisterFormState>();

    return (
        <AnoynmousLayout>
            <Container maxWidth={'lg'}>
                <PageSection
                    wrapperSx={{ ml: 10, mt: 5 }}
                    sx={{ pl: 0 }}
                    title='Register New User'
                >
                    <Form
                        ref={formRef}
                        formSchema={formSchema}
                        defaultValues={getDefaultValues()}
                        handleSubmitSuccess={handleSubmitSuccess}
                        handleSubmitError={handleSubmitError}
                        formWrapperProps={{
                            sx: { pl: 0, pr: 2, pt: 2, width: '100%' }
                        }}
                        render={({ control }) => (
                            <Grid spacing={2} container sx={{ overflow: 'auto' }}>
                                <TextField
                                    type="email"
                                    autoComplete='off'
                                    xs={12}
                                    label={'Email'}
                                    name={`email`}
                                    fieldName={`username`}
                                    placeholder={'Email'}
                                    control={control}
                                />
                                <TextField
                                    type="password"
                                    autoComplete='off'
                                    xs={6}
                                    label={'Password'}
                                    name={`password`}
                                    fieldName={`password`}
                                    placeholder={'Password'}
                                    control={control}
                                />
                                <TextField
                                    type="password"
                                    wrapperSx={{}}
                                    autoComplete='off'
                                    xs={6}
                                    label={'Confirm Password'}
                                    name={`confirmPassword`}
                                    fieldName={`confirmPassword`}
                                    placeholder={'Confirm Password'}
                                    control={control}
                                />
                                <TextField
                                    autoComplete='off'
                                    xs={12}
                                    label={'Name'}
                                    name={`name`}
                                    fieldName={`name`}
                                    placeholder={'Name'}
                                    control={control}
                                />
                                <TextField
                                    autoComplete='off'
                                    xs={12}
                                    label={'Address 1'}
                                    name={`address1`}
                                    fieldName={`address1`}
                                    placeholder={'Address 1'}
                                    control={control}
                                />
                                <TextField
                                    autoComplete='off'
                                    xs={12}
                                    label={'Address 2'}
                                    name={`address2`}
                                    fieldName={`address2`}
                                    placeholder={'Address 2 (Optional)'}
                                    control={control}
                                />
                                <TextField
                                    autoComplete='off'
                                    xs={5
                                    }
                                    label={'City'}
                                    name={`city`}
                                    fieldName={`city`}
                                    placeholder={'City'}
                                    control={control}
                                />
                                <SelectField
                                    xs={3}
                                    autoComplete='off'
                                    label={'State'}
                                    name={`state`}
                                    fieldName={`state`}
                                    items={STATES}
                                    control={control}
                                    allowEmpty
                                    placeholder={'Select State'}
                                />
                                <TextField
                                    autoComplete='off'
                                    xs={4}
                                    label={'Zip'}
                                    name={`zip`}
                                    fieldName={`zipCode`}
                                    placeholder={'Zip Code'}
                                    control={control}
                                />
                                                    <Actions>
                        <ActionButton component={Link} color='secondary' to='/signin'>Cancel & Go Back</ActionButton>
                        <ActionButton type="submit" >Register</ActionButton>
                    </Actions>
                            </Grid>
                        )}
                    />
                </PageSection>
            </Container>
        </AnoynmousLayout>
    );
}

const Actions = styled(Box)`
    padding-top: ${props => props.theme.spacing(10)};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
`;

const ActionButton = styled(Button)`
    min-width: 400px;
    padding-left: ${({ theme }) => theme.spacing(8)};
    padding-right: ${({ theme }) => theme.spacing(8)};
    flex: 0 0;
` as typeof Button;