import { createAction, createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { ApiQuoteSearchResponse } from '../../api/types';
import { ReduxRootState } from '../../shared/store';
import { ReduxSliceState, SliceState } from '../../shared/types';
import { DuplicateQuoteProps, GridQuote, LoadQuotesProps, ReduxQuoteSearchState, ToggleRowLoadingProps, UpdateQuoteRowStatusProps, UpdateQuoteStatusProps } from './types';

const initialState: ReduxQuoteSearchState = {
    state: 'BUSY',
    prevPage: undefined,
    quotes: [],
    nextPage: undefined,
    rowLoading: {},
    gridParams: {
        sortCol: 'id',
        sortDir: 'desc',
        limit: 50,
    },
    gridLookups: {
        customers: [],
        reps: [],
    },
};

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
  }

export const loadFeature = createAction('quotes/search/loadFeature', withPayloadType<void>());
export const loadQuoteLookups = createAction('quotes/search/loadLookups', withPayloadType<void>());
export const loadQuotes = createAction('quotes/search/loadQuotes', withPayloadType<LoadQuotesProps | undefined>());
export const loadQuotesPage = createAction('quotes/search/loadQuotesPage', withPayloadType<string>());
export const updateQuoteStatus = createAction('quotes/search/updateStatus', withPayloadType<UpdateQuoteStatusProps>());
export const duplicateQuote = createAction('quotes/search/duplicateQuote', withPayloadType<DuplicateQuoteProps>());

const quoteSearchSlice = createSlice({
    name: 'quoteSearch',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setQuotes: (state, action: PayloadAction<ApiQuoteSearchResponse>) => {
            const { payload: { previousPage, quotes, nextPage }} = action;
            state.prevPage = previousPage;
            state.quotes = [...quotes] as any;
            state.nextPage = nextPage;
        },
        updateQuoteRowStatus: (state, action: PayloadAction<UpdateQuoteRowStatusProps>) => {
            const rowIndex = state.quotes.findIndex(x => x.id === action.payload.row.id);
            if(rowIndex === -1)
                return;

            state.quotes[rowIndex] = {
                ...state.quotes[rowIndex],
                status: action.payload.newStatus,
            };
        },
        toggleQuoteRowLoading: (state, action: PayloadAction<ToggleRowLoadingProps>) => {
            if(state.rowLoading[action.payload.row.id] !== undefined) {
                delete state.rowLoading[action.payload.row.id];
                return;
            }

            state.rowLoading[action.payload.row.id] = true;
        },
        setGridParams: (state, action: PayloadAction<LoadQuotesProps>) => {
            state.gridParams = {...action.payload};
        },
        updatrGridParamStatus: (state, action: PayloadAction<string>) => {
            state.gridParams = {
                ...state.gridParams,
                quoteStatus: action.payload,
            };
        },
        setGridCustomers: (state, action: PayloadAction<string[]>) => {
            const sortedCustomers = action.payload.sort();
            state.gridLookups.customers = [
                ...sortedCustomers,
            ];
        },
        setGridReps: (state, action: PayloadAction<string[]>) => {
            const sortedReps = action.payload.sort();

            state.gridLookups.reps = [
                ...sortedReps,
            ];
        },
    }
});

const selectSelf = (state: ReduxRootState) => state.quoteSearch;
export const selectGridQuotes = createSelector(selectSelf, (slice: ReduxQuoteSearchState) => slice.quotes);
export const selectGridQuotesPaging = createSelector(selectSelf, ({ prevPage, nextPage }) => ({ prevPage, nextPage }));
export const selectGridState = createSelector(selectSelf, self => self.state);
export const selectGridQuotesBusy = createSelector(selectGridState, state => state === 'BUSY');
export const selectGridQuoteRowLoading = (quoteId: number) => createSelector(selectSelf, self => self.rowLoading[quoteId] !== undefined);
export const selectGridParams = createSelector(selectSelf, self => self.gridParams);
export const selectGridReps = createSelector(selectSelf, self => self.gridLookups.reps);
export const selectGridCustomers = createSelector(selectSelf, self => self.gridLookups.customers);
export const selectGridRepsList = createSelector(selectGridReps, reps => reps.map(x => ({ label: x, value: x })));
export const selectGridCustomersList = createSelector(selectGridCustomers, custs => custs.map(x => ({ label: x, value: x })));
export default quoteSearchSlice;