import { takeLatest, all, delay, put, select, call } from 'redux-saga/effects';
import dashboardSlice, { loadFeature} from './slice';
import { IConfiguration } from '../shared/types';
import { selectConfig } from '../shared/slice';
import { VerkadaApi } from '../api/verkada';
import { ApiDashboardResponse, ApiQuote, ApiQuoteProductItem } from '../api/types';
import { selectIdToken, unauthorized } from '../auth/slice';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

export abstract class DashboardSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadFeature, DashboardSagas.loadFeature),
            ]);
        }
        catch(e: any){
            console.log(`[DashboardSagas:run] The following exception has occurred`, e);
        }
    }

    private static *loadFeature(action: ReturnType<typeof loadFeature>) {
        try {

            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const dashboardResponse: AxiosResponse<ApiDashboardResponse> = yield call(
                { context: client, fn: client.getDashboardCards });
            
            if(dashboardResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(dashboardResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield put(dashboardSlice.actions.setMetrics(dashboardResponse.data));
        }
        catch(e: any){
            yield toast.error('An error has occurred fetching the dashboard', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});
        }
    }
}