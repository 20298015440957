import { createAction, createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { ApiQuoteProductItem } from '../../api/types';
import { LS_PRODUCTS_KEY } from '../../shared/constants';
import { ReduxRootState } from '../../shared/store';
import { ReduxSliceState, SliceState } from '../../shared/types';
import { AddToQuoteProps, CatalogCategoryDictionary, CatalogProduct, ReduxCatalogState, SerializedCatalogProducts } from './types';

const initialState: ReduxCatalogState = {
    state: 'BUSY',
    products: [],
};

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
  }

export const loadProducts = createAction('quotes/catalog/loadProducts', withPayloadType<void>());
export const addToQuote = createAction('addToQuote', withPayloadType<AddToQuoteProps>());

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setProducts: (state, action: PayloadAction<ApiQuoteProductItem[]>) => {
            state.products = [...action.payload];

            const localStorageProducts: SerializedCatalogProducts = {
                items: [...action.payload],
                expires: moment().add(30, 'minutes').unix(),
            };

            localStorage.setItem(LS_PRODUCTS_KEY, JSON.stringify(localStorageProducts));
        },

        rehydrateProducts: (state, action: PayloadAction<void>) => {
            const lsProducts = localStorage.getItem(LS_PRODUCTS_KEY);

            if(lsProducts === null || lsProducts === '')
                return;

            const { items, expires } = JSON.parse(lsProducts) as SerializedCatalogProducts;
            
            const now = moment().unix();
            //Is cache expired?
            if(now >= expires){
                localStorage.removeItem(LS_PRODUCTS_KEY);
                return;
            }

            state.products = [...items];
        },
    }
});

const selectSelf = (state: ReduxRootState) => state.catalog;

export const selectCatalogProducts = createSelector(selectSelf, catalog => catalog.products);
export const selectFullCatalogProducts = createSelector(selectSelf, catalog => catalog.products.reduce<CatalogCategoryDictionary>((agg, curr, index) => {
    let category = curr.category === undefined || curr.category === "" ? "Other" : curr.category;
    let series = curr.series === undefined || curr.series === "" ? "All" : curr.series;

    if(agg[category] === undefined)
        agg[category] = {};

    if(agg[category][series] === undefined)
        agg[category][series] = [];
    
        agg[category][series].push(curr);
    return agg;
}, {}));
export const selectCatalogState = createSelector(selectSelf, catalog => catalog.state);
export const selectCatalogIsBusy = createSelector(selectCatalogState, sliceState => sliceState === 'BUSY');
export default catalogSlice;