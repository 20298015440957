import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import sharedSlice, { loadApp } from '../shared/slice';
import catalogSlice, { addToQuote } from '../quotes/catalog/slice';
import settingsSlice from '../settings/slice';
import { SharedSagas } from './sagas';
import { fork, all } from 'redux-saga/effects';
import { QuoteCatalogSagas } from '../quotes/catalog/sagas';
import rootSaga from './rootSaga';
import authSlice, { confirmPasswordReset, register, sessionExpiration } from '../auth/slice';
import quoteSearch, { duplicateQuote } from '../quotes/search/slice';
import generateQuoteSlice, { addFromCatalog, resetState, updateQuote } from '../quotes/add-edit/slice';
import quoteAddEditSlice from '../quotes/add-edit/slice';
import usersSearch from '../users/search/slice';
import usersAddEdit, { createUser, updateUser } from '../users/add-edit/slice';
import dashboardSlice from '../dashboard/slice';

let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        shared: sharedSlice.reducer,
        catalog: catalogSlice.reducer,
        quoteSearch: quoteSearch.reducer,
        quoteAddEdit: quoteAddEditSlice.reducer,
        settings: settingsSlice.reducer,
        usersSearch: usersSearch.reducer,
        usersAddEdit: usersAddEdit.reducer,
        dashboard: dashboardSlice.reducer,
    },
    
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
            ignoredActions: [
                addFromCatalog.type,
                resetState.type,
                addToQuote.type,
                confirmPasswordReset.type,
                register.type,
                createUser.type,
                updateUser.type,
                sessionExpiration.type,
                loadApp.type,
                duplicateQuote.type,
                updateQuote.type,
            ],
        }
    }).concat(sagaMiddleware),
});

export type ReduxRootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);

export default store;