import { createAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSliceState } from "../../shared/types";
import { withPayloadType } from "../../shared/utils";
import { CreateUserProps, DeleteUserProps, LoadUserProps, ReduxUserAddEditState, UpdateUserProps, UserFormState } from "./types";
import { ReduxRootState } from "../../shared/store";
import { ApiRole } from "../../api/types";

const initialState: ReduxUserAddEditState = {
    state: 'BUSY',
    formState: undefined,
    roles: [],
};

export const loadFeature = createAction('users/add-edit/loadFeature', withPayloadType<void>());
export const loadUsers = createAction('users/add-edit/loadUsers', withPayloadType<void>());
export const loadUser = createAction('users/add-edit/loadUser', withPayloadType<LoadUserProps>());
export const loadRoles = createAction('users/add-edit/loadRoles', withPayloadType<void>());
export const createUser = createAction('users/add-edit/create', withPayloadType<CreateUserProps>());
export const updateUser = createAction('users/add-edit/update', withPayloadType<UpdateUserProps>());
export const deleteUser = createAction('users/add-edit/delete', withPayloadType<DeleteUserProps>());

const usersAddEditSlice = createSlice({
    name: 'usersAddEditSlice',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setRoles: (state, action: PayloadAction<ApiRole[]>) => {
            state.roles = [
                ...action.payload,
            ];
        },
        setFormState: (state, action: PayloadAction<UserFormState>) => {
            state.formState = {
                ...action.payload,
            }
        },
    }
});

export default usersAddEditSlice;

const selectSelf = (state: ReduxRootState) => state.usersAddEdit;
export const selectRoles = createSelector(selectSelf, self => self.roles);
export const selectFormState = createSelector(selectSelf, self => self.formState)