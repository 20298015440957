import { nanoid } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call, delay, select, putResolve } from 'redux-saga/effects';
import { ApiQuote, ApiQuoteLine, ApiQuoteProductItem } from '../../api/types';
import { VerkadaApi } from '../../api/verkada';
import { selectIdToken, selectUserFullName, unauthorized } from '../../auth/slice';
import { STATIC_BILLING_ADDRESS, STATIC_BILLING_ADDRESS2, STATIC_BILLING_CITY, STATIC_BILLING_NAME, STATIC_BILLING_STATE, STATIC_BILLING_ZIP } from '../../shared/constants';
import { selectConfig } from '../../shared/slice';
import { IConfiguration } from '../../shared/types';
import { calculateCustomerPrice, calculateDrivenPrice, calculateMargin, calculateMarginCustomer, calculateMarginDriven, calculateProductMSRPPrice, calculateProductPrice, formatWholeNumberPercent, mapStatusStringToEnum } from '../../shared/utils';
import { selectCatalogProducts } from '../catalog/slice';
import { toast } from 'react-hot-toast';
import quoteAddEditSlice, { loadFeature, resetState, addFromCatalog, createQuote, updateQuote, loadQuote } from './slice';
import { QuoteFormState, QuoteLine } from './types';
import { generatePath } from 'react-router-dom';

export abstract class QuoteAddEditSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadFeature.type, QuoteAddEditSagas.loadFeature),
                takeLatest(loadQuote.type, QuoteAddEditSagas.loadQuote),
                takeLatest(addFromCatalog.type, QuoteAddEditSagas.addFromCatalog),
                takeLatest(createQuote.type, QuoteAddEditSagas.createQuote),
                takeLatest(updateQuote.type, QuoteAddEditSagas.updateQuote),
                takeLatest(resetState.type, QuoteAddEditSagas.resetState),
            ]);
        }
        catch(e: any){
            console.log(`[QuoteAddEditSagas:run] the following exception has occurred`, e);
        }
    }

    private static *loadFeature(action: ReturnType<typeof loadFeature>){
        try {
            const { payload: { mode, id }} = action;
            yield put(quoteAddEditSlice.actions.setState('BUSY'));
            yield put(quoteAddEditSlice.actions.resetToInitialState());
            yield put(quoteAddEditSlice.actions.setMode(mode));
            if(mode === 'edit')
                yield call(QuoteAddEditSagas.loadQuote, loadQuote(id!));
        }
        catch(e: any){
            console.log(`[QuoteAddEditSagas:loadFeature] the following exception has occurred`, e);
        }
        finally {
            yield put(quoteAddEditSlice.actions.setState('READY'));
        }
    }

    private static *loadQuote(action: ReturnType<typeof loadQuote>) {
        try {
            const { payload: id } = action;

            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const getQuoteResponse: AxiosResponse<ApiQuote> = yield call({ context: client, fn: client.getQuote }, id.toString());
            
            if(getQuoteResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(getQuoteResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const products: ApiQuoteProductItem[] = yield select(selectCatalogProducts);
            const { quoteLines, discountPercentage, customerDiscount, taxExemptStatus, ...quoteData } = getQuoteResponse.data;

            const formStateData: Partial<QuoteFormState> = {
                ...quoteData,
                drivenSecurityMSRPDiscount: discountPercentage?.toString(),
                customerMSRPDiscount: customerDiscount?.toString(),
                taxExemptStatus: taxExemptStatus ? (taxExemptStatus.toString() === 'true' ? true : (taxExemptStatus.toString() === 'false' ? false : false)) : false,
                lines: [...quoteLines.map<QuoteLine>(ql => ({
                    uuid: nanoid(),
                    partNum: ql.partNum!,
                    partDesc: ql.partDesc!,
                    qty: ql.qty!,
                    price: ql.price!,
                    msrp: ql.msrp,
                    extendedPrice: ql.extendedPrice,
                    drivenSecurityMSRPDiscount: ql.drivenSecurityMSRPDiscount?.toString(),
                    customerMSRPDiscount: ql.customerMSRPDiscount?.toString(),
                    subtotal: ql.subtotal,
                })),
                {
                    uuid: nanoid(),
                    drivenSecurityMSRPDiscount: formatWholeNumberPercent(40),
                    customerMSRPDiscount: formatWholeNumberPercent(10),
                }],
            };

            yield put(quoteAddEditSlice.actions.setFormState(formStateData));
        }
        catch(e: any){

        }
    }

    private static *addFromCatalog(action: ReturnType<typeof addFromCatalog>){
        try {
            const { payload: { navigate, formState }} = action;
            yield put(quoteAddEditSlice.actions.setFormState(formState));
        }
        catch(e: any) {
            console.log(`[QuoteAddEditSagas:addFromCatalog] the following exception has occurred`, e);
        }
    }

    private static *resetState(action: ReturnType<typeof resetState>) {
        try {
            const { payload: { navigate }} = action;
            yield navigate('/quotes/create');
        }
        catch(e: any) {
            console.log(`[QuoteAddEditSagas:resetState] the following exception has occurred`, e);          
        }
    }

    private static *createQuote(action: ReturnType<typeof createQuote>) {
        try {

            yield put(quoteAddEditSlice.actions.setState('BUSY'));

            const { payload } = action;

            const config: IConfiguration = yield select(selectConfig);
            const userName: string = yield select(selectUserFullName);
            const idToken: string = yield select(selectIdToken);
            const products: ApiQuoteProductItem[] = yield select(selectCatalogProducts);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const validQuoteLines = payload.formState.lines!.filter(l => l.partNum !== undefined && l.partNum !== null && l.partNum !== '')
            var newQuoteRequest: ApiQuote = {
                repName: userName,
                billingName: STATIC_BILLING_NAME,
                billingAddress: STATIC_BILLING_ADDRESS,
                billingAddress2: STATIC_BILLING_ADDRESS2,
                billingCity: STATIC_BILLING_CITY,
                billingState: STATIC_BILLING_STATE,
                billingZip: STATIC_BILLING_ZIP,
                customerName: payload.formState.customerName,
                customerOrganization: payload.formState.customerOrganization,
                customerShipAddress: payload.formState.customerShipAddress,
                customerShipAddress2: payload.formState.customerShipAddress2,
                customerShipCity: payload.formState.customerShipCity,
                customerShipState: payload.formState.customerShipState,
                customerShipZip: payload.formState.customerShipZip,
                customerPhone: payload.formState.customerPhone,
                customerEmail: payload.formState.customerEmail,
                customerDiscount: 0,
                taxExemptStatus: payload.formState.taxExemptStatus,
                tax: payload.formState.tax,
                postingDate: payload.formState.postingDate,
                expirationDate: payload.formState.expirationDate,
                shippingType: payload.formState.shippingType,
                shippingAmount: payload.formState.shippingAmount,
                discountPercentage: calculateMargin(payload.formState.lines!, products),
                quoteLines: validQuoteLines
                    .map<ApiQuoteLine>(line => ({
                        partNum: line.partNum,
                        partDesc: line.partDesc,
                        qty: line.qty,
                        msrp: calculateProductMSRPPrice(products, line),
                        price: calculateCustomerPrice(products, line),
                        drivenPrice: calculateDrivenPrice(products, line),
                        cost: calculateProductPrice(products, line),
                        extendedPrice: calculateProductPrice(products, line),
                        drivenSecurityMSRPDiscount: !isNaN(Number(line.drivenSecurityMSRPDiscount?.replace('%',''))) ? Number(line.drivenSecurityMSRPDiscount?.replace('%','')) : undefined,
                        customerMSRPDiscount: !isNaN(Number(line.customerMSRPDiscount?.replace('%',''))) ? Number(line.customerMSRPDiscount?.replace('%','')) : undefined,
                    })),
            };
            
            const newQuoteResponse: AxiosResponse<ApiQuote> = yield call({ context: client, fn: client.createQuote }, newQuoteRequest);
            
            if(newQuoteResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;     
            }
            
            if(newQuoteResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");
            
            const { data: { id, url }} = newQuoteResponse;

            yield put(quoteAddEditSlice.actions.showAfterAction({ open: true, quoteId: id!, pdfUrl: url! }));
            yield put(quoteAddEditSlice.actions.setState('READY'));
        }
        catch(e: any) {
            console.log(`[QuoteAddEditSagas:createQuote] the following exception has occurred`, e);
        }
    }

    private static *updateQuote(action: ReturnType<typeof updateQuote>) {
        try {

            yield put(quoteAddEditSlice.actions.setState('BUSY'));
            const { payload: { formState, navigate } } = action;
            const { id, ...quoteUpdateForm } = formState;

            const config: IConfiguration = yield select(selectConfig);
            const userName: string = yield select(selectUserFullName);
            const idToken: string = yield select(selectIdToken);
            const products: ApiQuoteProductItem[] = yield select(selectCatalogProducts);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const validQuoteLines = formState.lines!.filter(l => l.partNum !== undefined && l.partNum !== null && l.partNum !== '')

            var updateQuoteReq: ApiQuote = {
                id,
                repName: userName,
                billingName: STATIC_BILLING_NAME,
                billingAddress: STATIC_BILLING_ADDRESS,
                billingAddress2: STATIC_BILLING_ADDRESS2,
                billingCity: STATIC_BILLING_CITY,
                billingState: STATIC_BILLING_STATE,
                billingZip: STATIC_BILLING_ZIP,
                status: '1',
                customerName: quoteUpdateForm.customerName,
                customerOrganization: quoteUpdateForm.customerOrganization,
                customerShipAddress: quoteUpdateForm.customerShipAddress,
                customerShipAddress2: quoteUpdateForm.customerShipAddress2,
                customerShipCity: quoteUpdateForm.customerShipCity,
                customerShipState: quoteUpdateForm.customerShipState,
                customerShipZip: quoteUpdateForm.customerShipZip,
                customerPhone: quoteUpdateForm.customerPhone,
                customerEmail: quoteUpdateForm.customerEmail,
                customerDiscount: 0,
                taxExemptStatus: quoteUpdateForm.taxExemptStatus,
                tax: quoteUpdateForm.tax,
                postingDate: quoteUpdateForm.postingDate,
                expirationDate: quoteUpdateForm.expirationDate,
                shippingType: quoteUpdateForm.shippingType,
                shippingAmount: quoteUpdateForm.shippingAmount,
                discountPercentage: calculateMargin(quoteUpdateForm.lines!, products),
                quoteLines: [...quoteUpdateForm.lines!
                    .filter(l => l.partNum !== undefined && l.partNum !== null && l.partNum !== '')
                    .map<ApiQuoteLine>(line => ({
                        partNum: line.partNum,
                        partDesc: line.partDesc,
                        qty: line.qty,
                        msrp: calculateProductMSRPPrice(products, line),
                        price: calculateCustomerPrice(products, line),
                        drivenPrice: calculateDrivenPrice(products, line),
                        cost: calculateProductPrice(products, line),
                        extendedPrice: calculateProductPrice(products, line),
                        drivenSecurityMSRPDiscount: !isNaN(Number(line.drivenSecurityMSRPDiscount?.replace('%',''))) ? Number(line.drivenSecurityMSRPDiscount?.replace('%','')) : undefined,
                        customerMSRPDiscount: !isNaN(Number(line.customerMSRPDiscount?.replace('%',''))) ? Number(line.customerMSRPDiscount?.replace('%','')) : undefined,
                    }))],
            };
            
            const updateQuoteResponse: AxiosResponse<ApiQuote> = yield call(
                {   context: client,
                    fn: client.updateQuote
                },
                id!,
                updateQuoteReq);
            
            if(updateQuoteResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }
            
            if(updateQuoteResponse.status !== 204)
                throw new Error("Error communicating with Verkada Api");

            yield toast.success(`Updated Quote# ${id}.`, { style: {
                fontWeight: 500,
                fontSize: '20px',
                maxWidth: '700px',       
            }});

            navigate(`/quotes/search`);
            yield put(quoteAddEditSlice.actions.setState('READY'));
        }
        catch(e: any) {
            console.log(`[QuoteAddEditSagas:updateQuote] the following exception has occurred`, e);
        }
    }
}