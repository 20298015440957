import { Box } from '@mui/material';
import React from 'react';

export type HeaderProps = {
    
}

export const Header: React.FC<HeaderProps> = (props) => {
    return (
        <>
            <Box>
                <img src="/images/Driven.png" alt="Company Logo" height="124"/>
            </Box>
        </>
    )
}
