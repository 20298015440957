import { createAction, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { ReduxAuthState, SerializedAuth, SetAuthProps, SignInProps, UnauthorizedProps, UserInfo, ResetPasswordProps, ConfirmResetPasswordProps, RegisterProps, SessionExpirationProps } from "./types";
import { LS_AUTH_KEY } from "../shared/constants";
import jwtDecode from 'jwt-decode';
import { ReduxSliceState } from "../shared/types";
import { ReduxRootState } from "../shared/store";
import { withPayloadType } from "../shared/utils";

export const login = createAction('login');

const initialState: ReduxAuthState = {
    signInState: 'READY',
    userInfo: undefined,
    token: undefined,
    refreshToken: undefined,
};

export const sessionExpiration = createAction('auth/sessionExpiration', withPayloadType<void>());
export const passwordReset = createAction('auth/passwordreset/request', withPayloadType<ResetPasswordProps>());
export const confirmPasswordReset = createAction('auth/passwordreset/confirm', withPayloadType<ConfirmResetPasswordProps>());
export const signIn = createAction('signin', withPayloadType<SignInProps>());
export const unauthorized = createAction('auth/unauthorized', withPayloadType<UnauthorizedProps>());
export const register = createAction('auth/register', withPayloadType<RegisterProps>());
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSignInState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.signInState = `${action.payload}`;
        },
        
        setAuth: (state, action: PayloadAction<SetAuthProps>) => {
            const { token, refreshToken } = action.payload;
            const userInfo = jwtDecode<UserInfo>(token);

            state.token = token;
            state.refreshToken = refreshToken;
            state.userInfo = {...userInfo};

            localStorage.setItem(LS_AUTH_KEY, JSON.stringify({
                token,
                refreshToken,
                userInfo,
            }));
        },

        rehydrateAuth: (state) => {
            const lsAuth = localStorage.getItem(LS_AUTH_KEY);

            //No auth stored in local storage
            if(lsAuth === null || lsAuth === '')
                return;

            const { token, refreshToken, userInfo } = JSON.parse(lsAuth) as SerializedAuth;
            state.token = token;
            state.refreshToken = refreshToken;
            state.userInfo = userInfo;
        },

        clearAuth: (state) => {
            state.token = undefined;
            state.refreshToken = undefined;
            state.userInfo = undefined;

            localStorage.removeItem(LS_AUTH_KEY);
        }
    }
});

const selectSelf = (state: ReduxRootState) => state.auth;
export const selectIsAuthenticated = createSelector(selectSelf, state => state.token !== undefined);
export const selectCurrentUser = createSelector(selectSelf, state => state.userInfo);
export const selectCurrentUserRole = createSelector(selectSelf, state => state.userInfo?.rolename);
export const selectUserFullName = createSelector(selectCurrentUser, state => `${state?.repname}`);
export const selectUserAvatarName = createSelector(selectUserFullName, name => 
    name.split(' ').map(n => n[0]).join('').toUpperCase()
);
export const selectIsSignInState = createSelector(selectSelf, state => state.signInState);
export const selectIsSignInBusy = createSelector(selectIsSignInState, state => state === 'BUSY');
export const selectIdToken = createSelector(selectSelf, state => state.token);
export const selectTokenExpiration = createSelector(selectSelf, state => state.userInfo?.exp)
export default authSlice;