import { createAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiRole, ApiUser } from "../../api/types";
import { ReduxRootState } from "../../shared/store";
import { ReduxSliceState } from "../../shared/types";
import { withPayloadType } from "../../shared/utils";
import { DeleteUserProps, FilterUserGridProps, LoadUsersProps, ReduxUsersSearchState } from "./types";

const initialState: ReduxUsersSearchState = {
    state: 'BUSY',
    gridUsers: [],
    gridLookups: {
        roles: [],
    },
    gridFilters: {
        search: undefined,
        role: undefined,
        sortCol: 'name',
        sortDir: 'asc',
    },
};

export const loadFeature = createAction('users/search/loadFeature', withPayloadType<void>());
export const loadUsers = createAction('users/search/loadUsers', withPayloadType<LoadUsersProps>());
export const deleteUser = createAction('users/search/deleteUser', withPayloadType<DeleteUserProps>());

const usersSearch = createSlice({
    name: 'usersSearch',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setUsers: (state, action: PayloadAction<ApiUser[]>) => {
            state.gridUsers = action.payload;
        },
        setGridLookupsRoles: (state, action: PayloadAction<ApiRole[]>) => {
            state.gridLookups.roles = [...action.payload];
        },
        setGridFilters: (state, action: PayloadAction<FilterUserGridProps>) => {
            state.gridFilters = { ...state.gridFilters, ...action.payload};
        },
    }
});

const selectSelf = (state: ReduxRootState) => state.usersSearch;
export const selectGridUsers = createSelector(selectSelf, self => self.gridUsers);
export const selectGridLookups = createSelector(selectSelf, self => self.gridLookups);
export const selectGridRoles = createSelector(selectGridLookups, lookups => lookups.roles.map(x => x).sort((a, b) => a.name.localeCompare(b.name))); 
export const selectGridUsersFilters = createSelector(selectSelf, self => self.gridFilters);
export const selectGridUsersFiltersSearch = createSelector(selectGridUsersFilters, filters => filters.search);
export const selectGridUsersFiltersRole = createSelector(selectGridUsersFilters, filters => filters.role);
export const selectGridUsersFiltersSortCol = createSelector(selectGridUsersFilters, filters => filters.sortCol);
export const selectGridUsersFiltersSortDir = createSelector(selectGridUsersFilters, filters => filters.sortDir);
export default usersSearch;