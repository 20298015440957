import { AxiosResponse } from "axios";
import { takeLatest, all, select, call, put } from "redux-saga/effects";
import { ApiQuoteSearchResponse, ApiUser } from "../../api/types";
import { VerkadaApi } from "../../api/verkada";
import { selectIdToken, unauthorized } from "../../auth/slice";
import { selectConfig } from "../../shared/slice";
import { IConfiguration } from "../../shared/types";
import usersSearch, { deleteUser, loadFeature, loadUsers } from "./slice";
import toast from "react-hot-toast";
import { loadRoles } from "../add-edit/slice";

export abstract class UsersSearchSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadFeature, UsersSearchSagas.loadFeature),
                takeLatest(loadUsers, UsersSearchSagas.loadUsers),
                takeLatest(loadRoles, UsersSearchSagas.loadRoles),
                takeLatest(deleteUser, UsersSearchSagas.deleteUser),
            ]);
        }
        catch(e: any){
            console.log(`[UsersSearchSagas:run] The following exception has occurred`, e);
        }
    }

    private static* loadFeature(action: ReturnType<typeof loadFeature>){
        try {
            yield put(loadRoles());
            yield put(loadUsers({}));
        }
        catch(e: any){
            toast.error('Error loading feature', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[UsersSearchSagas:loadFeature] The following exception has occurred`, e);
        }
    }

    private static *loadRoles(action: ReturnType<typeof loadRoles>) {
        try {
            yield put(usersSearch.actions.setState('BUSY'));
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const rolesResponse: AxiosResponse<any> = yield call(
                {
                    context: client,
                    fn: client.getRoles
                },
            );
            
            if(rolesResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(rolesResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield put(usersSearch.actions.setGridLookupsRoles(rolesResponse.data));
        }
        catch(e: any){
            toast.error('Error loading roles', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[UsersSearchSagas:loadRoles] The following exception has occurred`, e);
        }
    }

    private static *deleteUser(action: ReturnType<typeof deleteUser>) {
        const { payload: { email }} = action;
        try {
            const isConfirmed = window.confirm(`Are you sure you want to delete ${email}?`);
            if(!isConfirmed)
                return;
            
            yield put(usersSearch.actions.setState('BUSY'));
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const deleteUserResponse: AxiosResponse<any> = yield call(
                {
                    context: client,
                    fn: client.deleteUser
                },
                email
            );
            
            if(deleteUserResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(deleteUserResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield put(usersSearch.actions.setState('READY'));
            yield toast.success('User deleted successfully', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});

            yield put(loadUsers({}));
        }
        catch(e: any){
            toast.error('Error deleting user', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[UsersSearchSagas:deleteUser] The following exception has occurred`, e);
        }
    }

    public static *loadUsers(action: ReturnType<typeof loadUsers>) {
        const { payload: params } = action;
        try {
            yield put(usersSearch.actions.setState('BUSY'));
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const usersResponse: AxiosResponse<ApiUser[]> = yield call(
                {
                    context: client,
                    fn: client.getUsers
                }
            );
            
            if(usersResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(usersResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");


            const { data: gridUsers } = usersResponse;

            yield put(usersSearch.actions.setUsers(gridUsers));
            yield put(usersSearch.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[UsersSearchSagas:loadUsers] The following exception has occurred`, e);
        }
    }
}