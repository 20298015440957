import { AxiosResponse } from "axios";
import { takeLatest, all, call, select } from "redux-saga/effects";
import { VerkadaApi } from "../api/verkada";
import { selectIdToken } from "../auth/slice";
import { selectConfig } from "../shared/slice";
import { IConfiguration } from "../shared/types";
import { loadFeature } from "./slice";

export abstract class SettingsSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadFeature.type, SettingsSagas.loadFeature),
            ]);
        }
        catch(e: any){
            console.log(`[SettingsSagas:run] the following exception has occurred`, e);
        }
    }

    public static *loadFeature(action: ReturnType<typeof loadFeature>) {
        try {
            yield call(SettingsSagas.loadProfile);
        }
        catch(e: any){
            console.log(`[SettingsSagas:loadFeature] the following exception has occurred`, e);
        }
    }

    public static *loadProfile() {
        try {
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const getQuoteResponse: AxiosResponse<any> = yield call(
                {
                    context: client,
                    fn: client.getUserProfile
                });
            
        }
        catch(e: any){
            console.log(`[SettingsSagas:loadProfile] the following exception has occurred`, e);
        }
    }
}