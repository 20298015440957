import React from 'react';
import { Container, Typography, Box, styled, SxProps } from '@mui/material';

export type PageSectionProps = {
    title?: string,
    subtitle?: string,
    children: React.ReactNode | React.ReactNode[];
    controls?: React.ReactNode;
    sx?: SxProps,
    wrapperSx?: SxProps,
}

const HeaderWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-top: ${(props) => props.theme.spacing(0)};
    padding-left: ${(props) => props.theme.spacing(4)};
    padding-bottom: ${(props) => props.theme.spacing(4)};
    flex-grow: 1;
`;

const TitleWrapper = styled(Box)`
    display: flex;
    flex-grow: 1 0;
    padding-bottom: ${(props) => props.theme.spacing(0)};
`;

const ContentWrapper = styled(Box)`
    padding:  ${(props) => props.theme.spacing(0)};
    padding: 48px;
    display: flex;
    flex-wrap: wrap;
`;


export const PageSection: React.FC<PageSectionProps> = (props) => {
    const { title, subtitle, children, controls, sx, wrapperSx } = props;
    return (
        <HeaderWrapper sx={wrapperSx ?? {}}>
            <TitleWrapper sx={{ flexDirection: controls ? 'row' : 'column'}}>
                {title &&
                    <>
                    <Typography variant='h2' sx={{ display: 'block', flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {controls && controls}
                    </>
                }
            </TitleWrapper>
                {subtitle && 
                    <Typography variant="subtitle2" sx={{ display: 'block', flexShrink: 1, pt: 2 }}>
                        {subtitle}
                    </Typography>
                }
            <ContentWrapper sx={sx ?? {}}>
                {children}
            </ContentWrapper>
        </HeaderWrapper>
    );
}