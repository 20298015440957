import { AxiosResponse } from 'axios';
import { takeLatest, call, put, all, select, delay } from 'redux-saga/effects';
import { ApiRegisterRequest, SignInResponse } from '../api/types';
import { VerkadaApi } from '../api/verkada';
import { DEFAULT_UNAUTHORIZED_MESSAGE } from '../shared/constants';
import { selectConfig } from '../shared/slice';
import { IConfiguration } from '../shared/types';
import authSlice, { selectIdToken, signIn, unauthorized, passwordReset, confirmPasswordReset, register, sessionExpiration, selectTokenExpiration } from './slice';
import { toast } from 'react-hot-toast';
import { getInfoIcon } from '../shared/components/AnoynmousLayout';

export abstract class AuthSagas {
    public static* run(){
        try {
            yield all([
                takeLatest(signIn.type, AuthSagas.signIn),
                takeLatest(unauthorized.type, AuthSagas.unauthorized),
                takeLatest(passwordReset, AuthSagas.requestPasswordReset),
                takeLatest(confirmPasswordReset, AuthSagas.confirmPasswordReset),
                takeLatest(register, AuthSagas.register),
                takeLatest(sessionExpiration, AuthSagas.sessionExpiration),
            ]);
        }
        catch(e: any){
            console.log(`[SharedSagas:run] the following exception has occurred`, e);
        }
    }

    private static *sessionExpiration(action: ReturnType<typeof sessionExpiration>) {
        try {
            const tokenExpiration: number = yield select(selectTokenExpiration);
            const now = Math.floor(Date.now() * 0.001);
            
            const timeoutMS = Math.floor((tokenExpiration - now) * 1000);
            yield delay(timeoutMS);
            yield put(unauthorized({}));

            yield window.history.pushState({}, "", "/signin");

        }
        catch(e: any) {
            console.log(`[SecuritySagas:sessionExpiration] the following exception has occurred`, e);
        }
    }

    private static* register(action: ReturnType<typeof register>){
        const { payload } = action;
        const { navigate, ...req } = payload;
        try {
            const config: IConfiguration = yield select(selectConfig);
            var client = new VerkadaApi(config.apiBaseUrl);

            const resp: AxiosResponse<any> = yield call({
                context: client,
                fn: client.register,
            }, req as ApiRegisterRequest);

            if(resp.status !== 201){
                yield toast.error('An error has occurred registering a new user.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            yield toast.success('User created.', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});

            navigate("/signin");
        }
        catch(e: any) {
            console.log(`[SecuritySagas:register] the following exception has occurred`, e);
        }
    }

    private static* requestPasswordReset(action: ReturnType<typeof passwordReset>) {
        const { payload } = action;
        const { email } = payload;
        try {
            const config: IConfiguration = yield select(selectConfig);
            var client = new VerkadaApi(config.apiBaseUrl);

            const resp: AxiosResponse<any> = yield call({
                context: client,
                fn: client.resetPassword,
            }, email!);

            if(resp.status === 200) {
                yield toast.success('Check your email to complete your password reset', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            yield toast.error('Unable to process your request. Please try again.', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});
        }
        catch(e: any) {
            console.log(`[SecuritySagas:requestPasswordReset] the following exception has occurred`, e);
        }
    }

    private static* confirmPasswordReset(action: ReturnType<typeof confirmPasswordReset>){
        const { payload } = action;
        const { userId, token, password, confirmPassword, navigate } = payload;
        try {
            const config: IConfiguration = yield select(selectConfig);
            var client = new VerkadaApi(config.apiBaseUrl);

            const resp: AxiosResponse<any> = yield call({
                context: client,
                fn: client.resetPasswordConfirm,
            }, userId, token, {
                password,
                confirmPassword
            });

            if(resp.status === 200 && resp.data.status !== false) {
                yield toast.success('Your password has been changed', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                //Redirect back to signin
                yield navigate('/signin');
                return;
            }

            //Display serverside error message if available
            if(resp.status === 200 && resp.data?.status === false && resp.data?.message !== undefined){
                yield toast.error(`Serverside error: ${resp.data.message}`, { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            //Display a generic error toast if we reached this code
            yield toast.error('Unable to process your request. Please try again.', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});
        }
        catch(e: any) {
            yield toast.error('Unable to process your request. Please try again.', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});
            console.log(`[SecuritySagas:confirmPasswordReset] the following exception has occurred`, e);
        }
    }

    private static* signIn(action: ReturnType<typeof signIn>){
        const { payload: { username, password } } = action;
        try {
            yield put(authSlice.actions.setSignInState('BUSY'));
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const signInResponse: AxiosResponse<SignInResponse> = yield call(
                {
                    context: client,
                    fn: client.signIn
                },
                {
                    username,
                    password,
                }
            );

            if(signInResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const { token } = signInResponse.data;

            yield put(authSlice.actions.setAuth({
                token,
            }));

            //Set token expiration redirect
            yield put(sessionExpiration());
            
            yield put(authSlice.actions.setSignInState('READY'));
        }
        catch(e: any){
            toast.error('Incorrect username or password, please try again', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[SharedSagas:signIn] the following exception has occurred`, e);
        }
        finally {
            yield put(authSlice.actions.setSignInState('READY'));
        }
    }

    private static *unauthorized(action: ReturnType<typeof unauthorized>) {
        try {
            yield put(authSlice.actions.clearAuth());
            toast(action.payload?.message ?? DEFAULT_UNAUTHORIZED_MESSAGE, {
                icon: getInfoIcon()
            });

        }
        catch(e: any) {
            console.log(`[SecuritySagas:unauthorized] the following exception has occurred`, e);
        }
    }
}