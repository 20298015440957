import {
  FilledInput,
  FilledInputProps,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  SxProps,
  Theme,
  Skeleton,
} from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

export type TextFieldsProps<T extends FieldValues = any> = Omit<
  FilledInputProps,
  "onChange" | "value"
> & {
  fieldName: Path<T>;
  control: Control<T>;
  xs?: boolean | number | "auto";
  label: string;
  wrapperSx?: SxProps<Theme>;
  loading?: boolean;
};

export const TextField = <T extends FieldValues>(
  props: TextFieldsProps<T> & { formatter?: (value: any) => string}
): React.ReactElement => {
  const { control, label, name, fieldName, xs, wrapperSx, loading, readOnly, formatter, type, ...fieldProps } =
    props;
  return (
    <Grid item xs={xs} sx={{ ...wrapperSx }}>
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState, formState }) => {
          
          return (
            <FormControl
              fullWidth
              variant="filled"
              error={fieldState.error !== undefined}
              sx={{
                ".Mui-error.MuiInputBase-root": {
                  background: 'rgba(167, 65, 65, 0.1)',
                },
                ".Mui-error": {
                  'input': {
                    '&::placeholder': {
                      color: "#A74141",
                      opacity: 0,
                    }
                }},
                ".MuiInputBase-root": {
                  ...(readOnly ? {
                      background: 'none',
                      border: 'none',
                      'input': {
                      '&::placeholder': {
                        visibility: 'hidden',
                      }
                    }
                  }: {}),
                }
              }}
            >
              <InputLabel
                shrink={true}
                variant="filled"
                htmlFor={fieldName}
                disableAnimation
              >
                {label}
              </InputLabel>
              {loading ? 
                <Skeleton variant={'rectangular'} />
              :
                <FilledInput
                  id={fieldName}
                  name={field.name}
                  ref={field.ref}
                  type={formatter && readOnly ? "text": type}
                  error={fieldState.error !== undefined}
                  value={formatter && readOnly ? formatter(field.value ?? "") : field.value ?? ""}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  readOnly={readOnly}
                  fullWidth
                  {...fieldProps}
                  sx={{
                    ...fieldProps.sx,
                    ...(fieldState.error ? { border: '2px solid #A74141'} : { }),
                  }}
                />
              }
              {fieldState.error && (
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    </Grid>
  );
};
