import { createAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxRootState } from "./store";
import { IConfiguration, LoadAppProps, ReduxSharedState, ReduxSliceState } from "./types";
import { withPayloadType } from "./utils";

const initialState: ReduxSharedState = {
    state: 'BUSY',
    config: undefined,
    resetScroll: false,
};

export const loadApp = createAction('loadApp', withPayloadType<void>());

const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setConfig: (state, action: PayloadAction<IConfiguration>) => {
            state.config = action.payload;
        },
        resetScroll: (state) => {
            state.resetScroll = !state.resetScroll;
        }
    }
});

const selectSelf = (state: ReduxRootState) => state.shared;
export const selectSharedIsBusy = createSelector(selectSelf, self => self.state === 'BUSY');
export const selectConfig = createSelector(selectSelf, self => self.config);
export const selectWatchResetScroll = createSelector(selectSelf, self => self.resetScroll);
export default sharedSlice;