import { ISelectItem } from "./types";

export const LS_AUTH_KEY = 'auth';
export const LS_PRODUCTS_KEY = 'products';
export const STATIC_JWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
export const DEFAULT_UNAUTHORIZED_MESSAGE = 'Session Expired';

export const STATIC_BILLING_NAME = 'Driven Security';
export const STATIC_BILLING_ADDRESS = '10645 N Tatum Blvd';
export const STATIC_BILLING_ADDRESS2 = 'Ste 200-410';
export const STATIC_BILLING_CITY = 'Phoenix';
export const STATIC_BILLING_STATE = 'AZ';
export const STATIC_BILLING_ZIP = '85028';
export const STATIC_BILLING_PHONE = '';
export const STATIC_BILLING_EMAIL = '';

export const KPILabelMappings = {
  'approvedQuoteDollarMTD': 'Approved Quote Dollars (MTD)',
  'approvedQuoteAvgMarginMTD': 'Approved Quote Avg Margin (MTD)',
  'totalQuoteCountMTD': 'Total Quote Count (MTD)',
  'approvedQuoteDollarYTD': 'Approved Quote Dollars (YTD)',
  'approvedQuoteAvgMarginYTD': 'Approved Quote Avg Margin (YTD)',
  'totalQuoteCountYTD': 'Approved Quote Count (YTD)',
  'orderQuoteDollarYTD': 'Order Quote Dollars (YTD)',
  'orderQuoteAvgMarginYTD': 'Order Quote Average Margin (YTD)',
};

export const STATES: ISelectItem[] = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];