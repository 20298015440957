import React from 'react';
import { Grid, Select, SelectProps, FormHelperText, FormControl, InputLabel, MenuItem, SxProps, Theme, Skeleton, } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ISelectItem } from '../../types';

export type SelectFieldProps<T extends FieldValues = any> = Omit<SelectProps<T>, 'onChange' | 'value'> & {
    fieldName: Path<T>,
    control: Control<T>,
    xs?: boolean | number | 'auto'
    items: ISelectItem[];
    wrapperSx?: SxProps<Theme>;
    allowEmpty?: boolean;
    loading?: boolean;
}

const renderItems = (items: ISelectItem[], selectedValue: string = '') => (
    items.map((item, index) => <MenuItem key={item.value} selected={selectedValue === item.value} value={item.value}>{item.label}</MenuItem>)
)

export const SelectField = <T extends FieldValues,>(props: SelectFieldProps<T> & { formatter?: (value: any) => string}): React.ReactElement => {
    const { control, label, fieldName, xs, items, placeholder, wrapperSx, loading, allowEmpty = false, readOnly, formatter } = props;
    const showBlank = (placeholder !== undefined && placeholder !== '');
    return (
        <Grid item xs={xs} sx={{ ...wrapperSx}}>
            <Controller
                name={fieldName}
                control={control}
                render={({ field, fieldState, formState, }) => {
                    return (
                        <FormControl
                            variant='filled'
                            fullWidth
                            error={fieldState.error !== undefined}
                            sx={{
                                ".Mui-error.MuiInputBase-root": {
                                  background: 'rgba(167, 65, 65, 0.1)',
                                  border: '2px solid #A74141',
                                  color: "#A74141",
                                  "svg": {
                                    color: 'rgba(167, 65, 65, 0.75)',
                                  }
                                },
                                ".Mui-error": {
                                  'input': {
                                    '&::placeholder': {
                                      color: "#A74141",
                                      opacity: 0,
                                    }
                                }},
                                ".MuiInputBase-root": {
                                    ...(readOnly ? {
                                        '& svg[data-testid="ArrowDropDownIcon"]': {
                                            display: 'none',
                                        },
                                        background: 'none',
                                        border: 'none',
                                        'input': {
                                        '&::placeholder': {
                                          visibility: 'hidden',
                                        },
                                      }
                                    }: {}),
                                  },
                              }}
                        >
                            <InputLabel
                                shrink={true}
                                variant='filled'
                                htmlFor={fieldName}
                                disableAnimation
                            >
                                {label}
                            </InputLabel>
                            {loading
                                ?
                                    <Skeleton variant={'rectangular'} />
                                :
                                    <>
                                    <Select
                                        readOnly={readOnly}
                                        displayEmpty={showBlank}
                                        variant={'filled'}
                                        id={fieldName}
                                        inputRef={field.ref}
                                        name={field.name}
                                        label={label}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        fullWidth
                                        color={'primary'}
                                    >
                                            <MenuItem sx={{ display : `${allowEmpty ? 'inherit' : 'none'}`}} disabled={!allowEmpty} selected={field.value === ''} value="">
                                                <em>{placeholder ?? 'Empty'}</em>
                                            </MenuItem>
                                        {renderItems(items, field.value as string)}
                                    </Select>
                                    {fieldState.error && (
                                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                    )}
                                    </>
                            }
                        </FormControl>
                    )
                }}
            />
        </Grid>
    )
}