import { AxiosResponse } from 'axios';
import { takeLatest, all, delay, put, select, call } from 'redux-saga/effects';
import { ApiQuote, ApiQuoteSearchResponse } from '../../api/types';
import { VerkadaApi } from '../../api/verkada';
import { selectConfig } from '../../shared/slice';
import { IConfiguration } from '../../shared/types';
import { mapStatusEnumToString } from '../../shared/utils';
import quoteSearchSlice, { duplicateQuote, loadFeature, loadQuoteLookups, loadQuotes, loadQuotesPage, selectGridParams, updateQuoteStatus } from './slice';
import { toast } from 'react-hot-toast';
import { selectIdToken, unauthorized } from '../../auth/slice';
import { LoadQuotesProps } from './types';

export abstract class QuoteSearchSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadFeature.toString(), QuoteSearchSagas.loadFeature),
                takeLatest(loadQuotes.toString(), QuoteSearchSagas.loadQuotes),
                takeLatest(updateQuoteStatus, QuoteSearchSagas.updateQuoteStatus),
                takeLatest(loadQuotesPage, QuoteSearchSagas.loadQuotesUrl),
                takeLatest(duplicateQuote, QuoteSearchSagas.duplicateQuote),
            ]);
        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:run] The following exception has occurred`, e);
        }
    }

    private static *loadFeature(action: ReturnType<typeof loadFeature>){
        try {
            yield put(quoteSearchSlice.actions.setState('BUSY'));
            const gridParams: LoadQuotesProps = yield select(selectGridParams);
            yield call(QuoteSearchSagas.loadQuotes, loadQuotes(gridParams));
            yield call(QuoteSearchSagas.loadQuoteLookups, loadQuoteLookups());
            yield put(quoteSearchSlice.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:loadFeature] The following exception has occurred`, e);
        }
    }

    private static* loadQuoteLookups(action: ReturnType<typeof loadQuoteLookups>) {
        try {
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const repsResponse: AxiosResponse<string[]> = yield call({
                context: client,
                fn: client.getQuoteReps,
            });

            if(repsResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(repsResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const customersResponse: AxiosResponse<string[]> = yield call({
                context: client,
                fn: client.getQuoteCustomers,
            });

            if(customersResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(customersResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield put(quoteSearchSlice.actions.setGridReps(repsResponse.data));
            yield put(quoteSearchSlice.actions.setGridCustomers(customersResponse.data));

        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:loadQuoteLookups] The following exception has occurred`, e);
        }
    }

    private static *updateQuoteStatus(action: ReturnType<typeof updateQuoteStatus>){
        const { payload: { row , newStatus } } = action;
        const { id } = row;

        try {
            yield put(quoteSearchSlice.actions.toggleQuoteRowLoading({ row }));
            
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const quotesResponse: AxiosResponse<ApiQuoteSearchResponse> = yield call({
                context: client,
                fn: client.updateQuoteStatus
            },
                id,
                newStatus
            );

            if(quotesResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(quotesResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield toast.success('Quote Status Updated', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});           
            yield put(quoteSearchSlice.actions.updateQuoteRowStatus({ row, newStatus }));
            
        }
        catch(e: any){
            toast.error('Could not update quote status', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[QuoteSearchSagas:updateQuoteStatus] The following exception has occurred`, e);
        }
        finally {
            yield put(quoteSearchSlice.actions.toggleQuoteRowLoading({ row }));
        }
    }

    private static *loadQuotes(action: ReturnType<typeof loadQuotes>){
        const { payload: params } = action;
        try {
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const quotesResponse: AxiosResponse<ApiQuoteSearchResponse> = yield call(
                {
                    context: client,
                    fn: client.getQuotes
                },
                {
                    ...params,
                }
            );
            
            if(quotesResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(quotesResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const { data: { previousPage, quotes, nextPage } } = quotesResponse;

            yield put(quoteSearchSlice.actions.setQuotes({
                previousPage,
                quotes: [...quotes],
                nextPage,
            }));
            
            yield put(quoteSearchSlice.actions.setGridParams({ ...params }));
            yield put(quoteSearchSlice.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:loadQuotes] The following exception has occurred`, e);
        }
    }

    private static* loadQuotesUrl(action: ReturnType<typeof loadQuotesPage>){
        try {
            const { payload: url } = action;

            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const quotesResponse: AxiosResponse<ApiQuoteSearchResponse> = yield call({ context: client, fn: client.getQuotesFullUrl }, url);
            
            if(quotesResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(quotesResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const { data: { previousPage, quotes, nextPage } } = quotesResponse;

            yield put(quoteSearchSlice.actions.setQuotes({
                previousPage,
                quotes: quotes,
                nextPage,
            }));
            yield put(quoteSearchSlice.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:loadQuotesUrl] The following exception has occurred`, e);
        }
    }

    private static* duplicateQuote(action: ReturnType<typeof duplicateQuote>){
        try {
            const { payload: { navigate, quoteId } } = action;

            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const duplicateQuoteResponse: AxiosResponse<ApiQuote> = yield call({ context: client, fn: client.duplicateQuote }, quoteId);
            
            if(duplicateQuoteResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(duplicateQuoteResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            const { data: { id: duplicateId }} = duplicateQuoteResponse;
            if(!duplicateId)
                throw new Error('Could not read duplicate quote id.');

            navigate(`/quotes/edit/${duplicateId}`);
            yield toast.success(`Duplicate quote created from Quote# ${quoteId}.`, { style: {
                fontWeight: 500,
                fontSize: '20px',
                maxWidth: '700px',       
            }});
        }
        catch(e: any){
            console.log(`[QuoteSearchSagas:duplicateQuote] The following exception has occurred`, e);
            yield toast.error('Could not duplicate quote.', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
        }
    }
}