import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { withPayloadType } from "../shared/utils";
import { ReduxSettingsState, UserProfile } from "./types";

const initialState: ReduxSettingsState = {
    profile: {}
};

export const loadFeature = createAction('settings/loadFeature', withPayloadType<void>());

const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload;
        }
    }
});

export default settingsSlice;