import { Box } from '@mui/material';
import React from 'react';
import { Header } from './Header';
import InfoIcon from '@mui/icons-material/Info';

export type AnoynmousLayoutProps = {
    children: React.ReactNode | React.ReactNode[];
}

export const AnoynmousLayout: React.FC<React.PropsWithChildren<AnoynmousLayoutProps>> = (props) => {
    const { children } = props;
    return (
        <Box sx={{  padding: 5}}>
              <Header />
              {children}
        </Box>
    );
}

export const getInfoIcon = () => (
    <InfoIcon color={'info'} />
)