import type {} from '@mui/x-date-pickers/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const AppTheme = createTheme({
    palette: {
        primary: {
          main: '#e98c2e',
        },
        secondary: {
          main: '#313035',
        },
        error: {
            main: '#A74141',
        },
    },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    body1: {
      color: "#ffffff",
      fontSize: "26px",
      fontWeight: 300,
    },
    body2: {
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "26px",
    },
    h1: {
      color: "#ffffff",
      fontSize: "48px",
      fontFamily: "Open Sans",
      fontWeight: 500,
    },
    h2: {
      color: "#ffffff",
      fontSize: "36px",
      fontWeight: 600,
    },
    h3: {
      color: "#ffffff",
      fontSize: "36px",
      fontFamily: "Open Sans",
      letterSpacing: "0.6rem",
      textTransform: "uppercase",
    },
    h4: {
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "26px",
    },

    subtitle1: {
      color: "#ffffff",
      fontSize: "26px",
      fontFamily: "Open Sans",
      lineHeight: "32px",
      fontWeight: 300,
    },
    subtitle2: {
      color: "#FFFFFFBF",
      fontSize: "26px",
      lineHeight: "42px",
      fontWeight: 100,
      fontStyle: 'italic',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: (props) => ({
          fontWeight: 600,
          fontSize: "26px",
          display: "block",
          position: "static",
          paddingBottom: props.theme.spacing(2),
        }),
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },
          style: (props) => ({
            ".MuiIconButton-root": {
              paddingRight: "2rem",
              color: "#ffffff",
              svg: {
                fontSize: "2.25rem",
              },
            },
          }),
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "filled" },
          style: (props) => ({
            ".MuiIconButton-root": {
              paddingRight: "2rem",
              color: "#ffffff",
              svg: {
                fontSize: "2.25rem",
              },
            },
          }),
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ".MuiSelect-iconFilled": {
            color: "#ffffff",
            fontSize: "3rem",
            marginRight: "1.25rem",
          },
          input: {
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              fontFamily: "Open Sans",
              fontSize: '26px',
            },
          },
        },
      },
    },

    MuiSkeleton: {
      variants: [
        {
        props: { variant: 'rectangular' },
        style: (props) => ({
          borderRadius: '21px',
          minHeight: '90px',
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.11)',
        }),
        },
      ]
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".Mui-error.MuiOutlinedInput-notchedOutline": {
            background: 'rgba(167, 65, 65, 0.1)',
            border: '2px solid #A74141',
        },
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "21px",
          background: "#FFFFFF30",
          border: "1px solid #FFFFFF80",
          color: "#FFFFFFBF",
          height: "90px",
          fontFamily: "Open Sans",
          fontSize: "26px",
          "&.Mui-error.MuiOutlinedInput-notchedOutline": {
            background: 'rgba(167, 65, 65, 0.1)',
            border: '2px solid #A74141',
        },
        },

        input: (props) => ({
          paddingLeft: props.theme.spacing(4),
          paddingTop: props.theme.spacing(2),
          lineHeight: "32px",
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            fontWeight: 300,
            color: "#FFFFFF",
          },
        }),
      },
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          fontSize: "1rem",
          fontFamily: "Open Sans",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "text",
        disableRipple: false,
        fullWidth: false,
        sx: {
          boxSizing: "border-box",
          flex: "1 1",
        },
      },
      variants: [
        {
          props: { variant: "text", color: "primary" },
          style: (props) => ({
            color: "#ffffff",
            background: props.theme.palette.primary.main,
            borderRadius: "42px",
            fontFamily: "Open Sans",
            fontSize: "30px",
            marginLeft: props.theme.spacing(2),
            textTransform: "none",
          }),
        },
        {
          props: { variant: "text", color: "secondary" },
          style: (props) => ({
            backgroundColor: props.theme.palette.secondary.main,
            color: "#ffffff",
            borderRadius: "42px",
            fontFamily: "Open Sans",
            fontSize: "30px",
            marginRight: props.theme.spacing(2),
            textTransform: "none",
          }),
        },
        {
          props: { variant: "text", color: "info" },
          style: (props) => ({
            color: "#ffffff",
            fontFamily: "Open Sans",
            fontSize: "24px",
            textTransform: "none",
            'span': {
                'svg': {
                    fontSize: '24px !important',
                },
              paddingRight: props.theme.spacing(2),
            },
          }),
        },
      ],
    },
  },
});