import { AxiosResponse } from 'axios';
import { HttpClient } from './httpClient';
import { ApiConfirmPasswordResetRequest, ApiDashboardResponse, ApiQuote, ApiQuoteCreateRequest, ApiQuoteProductItem, ApiQuoteSearchResponse, ApiRegisterRequest, ApiRole, ApiUpdateUserProfile, ApiUser, QuoteSearchParams, SignInRequest, SignInResponse } from './types';

export class VerkadaApi {
    private readonly baseUrl: string;
    private readonly http: HttpClient;
  
    constructor(baseUrl: string, idToken?: string) {
      this.baseUrl = baseUrl;
      this.http = new HttpClient({
        baseURL: this.baseUrl,
        withCredentials: false,
        headers: {
          ...(idToken ? { 'Authorization' : `Bearer ${idToken}` } : {}),
      },
      validateStatus: (_) => true,
      });
    }

    public async signIn(req: SignInRequest): Promise<AxiosResponse<SignInResponse>> {
      return await this.http.post<SignInRequest, SignInResponse>(`/Auth/signin`, req);
    }

    public async getQuotes(params?: QuoteSearchParams): Promise<AxiosResponse<ApiQuoteSearchResponse>> {
      return await this.http.get<ApiQuoteSearchResponse>("/quotes/search", { params: {
        ...params,
      }});
    }

    public async getQuotesFullUrl(url: string): Promise<AxiosResponse<ApiQuoteSearchResponse>> {
      return await this.http.get<ApiQuoteSearchResponse>(url);
    }

    public async getQuote(id: string): Promise<AxiosResponse<ApiQuote>> {
      return await this.http.get<ApiQuote>(`/quotes/${id}`);
    }

    public async updateQuote(id: string | number, update: ApiQuote): Promise<AxiosResponse<ApiQuote>> {
      return await this.http.put<ApiQuote,ApiQuote>(`/quotes/${id}`, update);
    }

    public async createQuote(quote: ApiQuoteCreateRequest): Promise<AxiosResponse<ApiQuote>> {
      return await this.http.post<ApiQuoteCreateRequest, ApiQuote>(`/quotes`, quote);
    }

    public async deleteQuote(id: string | number): Promise<AxiosResponse<any>> {
      return await this.http.delete(`/quotes/${id}`);
    }

    public async duplicateQuote(id: string | number): Promise<AxiosResponse<ApiQuote>> {
      return await this.http.post(`/quotes/duplicate`, {}, { params: { id }});
    }

    public async getProducts(): Promise<AxiosResponse<ApiQuoteProductItem[]>> {
      return await this.http.get<ApiQuoteProductItem[]>(`/products`);
    }

    public async getProduct(sku: string): Promise<AxiosResponse<ApiQuoteProductItem>> {
      return await this.http.get<ApiQuoteProductItem>(`/products/${sku}`);
    }

    public async updateQuoteStatus(quoteId: string | number, statusId: string): Promise<AxiosResponse> {
      return await this.http.post(`/quotes/updateStatus/${quoteId}/${statusId}`);
    }

    public async getUsers(): Promise<AxiosResponse<ApiUser[]>> {
      return await this.http.get(`/users`);
    }

    public async getQuoteCustomers(): Promise<AxiosResponse<string[]>> {
      return await this.http.get(`/quotes/customerLookup`);
    }

    public async getQuoteReps(): Promise<AxiosResponse<string[]>> {
      return await this.http.get(`/quotes/repNameLookup`);
    }

    public async resetPassword(email: string): Promise<AxiosResponse<void>> {
      return await this.http.post(`/Auth/passwordreset/${encodeURIComponent(email)}/request`);
    }

    public async resetPasswordConfirm(userId: string, token: string, req: ApiConfirmPasswordResetRequest): Promise<AxiosResponse<any>> {
      return await this.http.post(`/Auth/passwordreset/${userId}/change?token=${encodeURIComponent(token)}`, req);
    }

    public async getUserProfile(): Promise<AxiosResponse<any>> {
      return await this.http.get(`/users/profile`);
    }

    public async register(req: ApiRegisterRequest): Promise<AxiosResponse<any>> {
      return await this.http.post(`/Auth/register`, req);
    }

    public async deleteUser(email: string) : Promise<AxiosResponse<any>> {
      return await this.http.delete(`/Users/${encodeURIComponent(email)}`);
    }

    public async updateUser(req: ApiUpdateUserProfile): Promise<AxiosResponse<any>> {
      return await this.http.put(`/Users/profile`, req);
    }

    public async getRoles(): Promise<AxiosResponse<ApiRole[]>> {
      return await this.http.get(`/Roles`)
    }

    public async getUserRoles(email: string): Promise<AxiosResponse<string[]>> {
      return await this.http.get(`/Roles/GetUserRoles?email=${email}`);
    }

    public async addUserToRole(email: string, roleName: string): Promise<AxiosResponse<any>> {
      return await this.http.post(`/Roles/AddUserToRole?email=${encodeURIComponent(email)}&roleName=${encodeURIComponent(roleName)}`);
    }

    public async removeUserFromRole(email: string, roleName: string) : Promise<AxiosResponse<any>> {
      return await this.http.post(`/Roles/RemoveUserFromRole?email=${email}&roleName=${roleName}`);
    }

    public async getDashboardCards(): Promise<AxiosResponse<ApiDashboardResponse>> {
      return await this.http.get(`/Dashboard`);
    }
}