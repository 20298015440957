import { all, fork } from "redux-saga/effects";
import { AuthSagas } from "../auth/sagas";
import { QuoteAddEditSagas } from "../quotes/add-edit/sagas";
import { QuoteCatalogSagas } from "../quotes/catalog/sagas";
import { QuoteSearchSagas } from "../quotes/search/sagas";
import { SettingsSagas } from "../settings/sagas";
import { UsersSearchSagas } from "../users/search/sagas";
import { SharedSagas } from "./sagas";
import { UsersAddEditSagas } from "../users/add-edit/sagas";
import { DashboardSagas } from "../dashboard/sagas";

// eslint-disable-next-line import/no-anonymous-default-export
export default function*() {
    yield all([
        fork(AuthSagas.run),
        fork(SharedSagas.run),
        fork(DashboardSagas.run),
        fork(QuoteCatalogSagas.run),
        fork(QuoteSearchSagas.run),
        fork(QuoteAddEditSagas.run),
        fork(UsersSearchSagas.run),
        fork(UsersAddEditSagas.run),
        fork(SettingsSagas.run),
    ]);
}