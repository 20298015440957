import { Grid } from '@mui/material';
import React, { useImperativeHandle, useRef } from 'react';
import { Control, DeepPartial, DeepRequired, FieldErrors, FieldErrorsImpl, FieldValues, FormState, useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, number, string, date, ObjectSchema } from 'yup';
import styled from 'styled-components';

export interface IFormProps<T extends FieldValues> extends Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>,HTMLFormElement>, 'children' | 'onSubmit'> {
    formWrapper?: React.ComponentType<any>,
    formWrapperProps?: React.ComponentProps<any>,
    formSchema: ObjectSchema<T, never>,
    defaultValues: T,
    handleSubmitSuccess: (formData: T, e?: React.BaseSyntheticEvent) => void,
    handleSubmitError?: (errors: FieldErrors<T>, e?: React.BaseSyntheticEvent) => void,
    render: (form: UseFormReturn<T>) => React.ReactNode | React.ReactNode[],
}

const Form = styled.form`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto;
    alignItems: stretch;
`;

export const createForm = <T extends FieldValues,>() => React.forwardRef<any, IFormProps<T>>((props, ref) => {
    const { formSchema, formWrapper: FormWrapper = Grid, formWrapperProps = { container: true, spacing: 2 }, defaultValues, handleSubmitSuccess, handleSubmitError } = props;
    const form = useForm<T>({
        resolver: yupResolver(formSchema),
        defaultValues: {
            ...defaultValues as DeepPartial<T>
        }
    });
    const formRef = useRef<HTMLFormElement>(null);
    
    useImperativeHandle(ref, () => ({

    getControl(){
        return form.control;
    },

    getForm(){
        return formRef.current;
    }

    }));

    return (
        <>
            <Form {...props} ref={formRef} onSubmit={form.handleSubmit(handleSubmitSuccess, handleSubmitError)}>
                <FormWrapper {...formWrapperProps}>
                    {props.render(form)}
                </FormWrapper>
            </Form>
        </>
    )
});