import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs, { Dayjs } from 'dayjs';
import {
  FormControl,
  Grid,
  FilledInputProps,
  SxProps,
  Theme,
  FilledInput,
  Skeleton,
  InputLabel,
  FormHelperText,
  styled,
  TextFieldProps,
} from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EventIcon from '@mui/icons-material/Event';

export type DateFieldProps<T extends FieldValues = any> = Omit<
  FilledInputProps,
  "onChange" | "value"
> & {
  fieldName: Path<T>;
  control: Control<T>;
  xs?: boolean | number | "auto";
  label: string;
  wrapperSx?: SxProps<Theme>;
  loading?: boolean;
  disableMaskedInput?: boolean;
};

const CustomInput = styled(MuiTextField)(({ theme }) => ({

}));

CustomInput.defaultProps = {
  variant: 'filled',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
  InputProps: {
    sx:{
      ".Mui-error.MuiInputBase-root": {
        background: 'rgba(167, 65, 65, 0.1)',
        "svg": {
          color: 'rgba(167, 65, 65, 0.75)',
        }
      },
      ".Mui-error.MuiOutlinedInput-notchedOutline": {
        background: 'rgba(167, 65, 65, 0.1)',
        border: '2px solid #A74141',
        color: "#A74141",
        "svg": {
          color: 'rgba(167, 65, 65, 0.75)',
        }
      },
      ".Mui-error": {
        'input': {
          '&::placeholder': {
            color: "#A74141",
            opacity: 0,
          }
      }},
    },
  },
  sx:{
    ".Mui-error.MuiInputBase-root": {
      background: 'rgba(167, 65, 65, 0.1)',
      "svg": {
        color: 'rgba(167, 65, 65, 0.75)',
      },
      border: '2px solid #A74141',
    },
    ".Mui-error.MuiOutlinedInput-notchedOutline": {
      background: 'rgba(167, 65, 65, 0.1)',
      border: '2px solid #A74141',
      color: "#A74141",
      "svg": {
        color: 'rgba(167, 65, 65, 0.75)',
      }
    },
    ".Mui-error": {
      'input': {
        '&::placeholder': {
          color: "#A74141",
          opacity: 0,
        }
    }},
  },
}

const DateFieldCustom: React.FC<TextFieldProps> = (props) => (
  <>
    <CustomInput {...props} />
  </>
)

const DateFieldSkeleton: React.FC<TextFieldProps> = (props) => (
  <>
    <FormControl
      fullWidth
      variant="filled"
      sx={{
        ".Mui-error.MuiInputBase-root": {
          background: 'rgba(167, 65, 65, 0.1)',
        },
        ".Mui-error": {
          'input': {
            '&::placeholder': {
              color: "#A74141",
              opacity: 0,
            }
          }
        },
      }}
    >
      <InputLabel
        shrink={true}
        variant="filled"
        htmlFor={props.name}
        disableAnimation
      >
        {props.label}
      </InputLabel>
      <Skeleton variant={'rectangular'} />
    </FormControl>
  </>
);

export const DateField = <T extends FieldValues>(
  props: DateFieldProps<T> & {  loading?: boolean; }
): React.ReactElement => {
  const { control, label, fieldName, xs, wrapperSx, loading, readOnly, disableMaskedInput } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={xs} sx={{ ...wrapperSx }}>
        <Controller
          name={fieldName}
          control={control}
          render={({ field, fieldState, formState }) => {
            return (
              <>
              <DatePicker label={label} readOnly={readOnly} slotProps={{
                textField: {
                  sx: {
                    ".MuiInputBase-root": {
                      ...(readOnly ? {
                          background: 'none',
                          border: 'none',
                          'input': {
                              '&::placeholder': {
                                  visibility: 'hidden',
                              },
                              cursor: 'text'
                          }
                      } : {}),
                      ".MuiInputAdornment-root": {
                      }
                  },
                  },
                  error: fieldState.error !== undefined,
                },
              }} onChange={field.onChange} value={dayjs(field.value)} slots={{
                textField:loading ? DateFieldSkeleton : DateFieldCustom
              }} />
              {fieldState.error && (<FormHelperText sx={{ ml: 2, color: '#A74141', }}>{fieldState.error?.message}</FormHelperText>)}
              </>
            );
            }} />
      </Grid>
    </LocalizationProvider>
  );
};
