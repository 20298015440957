import { createAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxRootState } from "../shared/store";
import { ReduxSliceState } from "../shared/types";
import { withPayloadType } from "../shared/utils";
import { ReduxDashboardState } from "./types";
import { ApiDashboardResponse } from "../api/types";

const initialState: ReduxDashboardState = {
    state: 'BUSY',
    metrics: [],
};

export const loadFeature = createAction('dashboard/loadFeature', withPayloadType<void>());

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<ReduxSliceState>) => {
            state.state = action.payload;
        },
        setMetrics: (state, action: PayloadAction<ApiDashboardResponse>) => {
            state.metrics = [
                ...action.payload.items.map(x => x),
            ]
        }
    }
});

export default dashboardSlice;

const selectSelf = (state: ReduxRootState) => state.dashboard;
export const selectDashboardCards = createSelector(selectSelf, self => self.metrics);