import { takeLatest, all, put, select, call } from "redux-saga/effects";
import usersAddEditSlice, { createUser, loadFeature, loadRoles, loadUser, updateUser } from "./slice";
import { IConfiguration } from "../../shared/types";
import { AxiosResponse } from "axios";
import { VerkadaApi } from "../../api/verkada";
import { selectConfig } from "../../shared/slice";
import { selectIdToken, unauthorized } from "../../auth/slice";
import toast from "react-hot-toast";
import { ApiRegisterRequest, ApiUpdateUserProfile } from "../../api/types";
import { UsersSearchSagas } from "../search/sagas";
import { loadUsers, selectGridUsers } from "../search/slice";
import { GridUser } from "../search/types";

export abstract class UsersAddEditSagas {
    public static* run(){
        try {
            yield all([
                takeLatest(loadFeature, UsersAddEditSagas.loadFeature),
                takeLatest(loadRoles, UsersAddEditSagas.loadRoles),
                takeLatest(createUser, UsersAddEditSagas.createUser),
                takeLatest(updateUser, UsersAddEditSagas.updateUser),
                takeLatest(loadUser, UsersAddEditSagas.loadUser),
            ]);
        }
        catch(e: any){
            console.log(`[UsersAddEditSagas:run] the following exception has occurred`, e);
        }
    }

    private static* loadUser(action: ReturnType<typeof loadUser>) {
        const { payload: { userId } } = action;
        try {
            yield put(usersAddEditSlice.actions.setState('BUSY'));
            yield call(UsersAddEditSagas.loadRoles, loadRoles());
            yield call(UsersSearchSagas.loadUsers, loadUsers({ }));

            const users: GridUser[] = yield select(selectGridUsers);
            const user: GridUser = users.find(x => x.id === userId)!;

            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);

            const resp: AxiosResponse<string[]> = yield call({
                context: client,
                fn: client.getUserRoles,
            }, user.email!);

            if(resp.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(resp.status !== 200){
                yield toast.error('An error has occurred loading user roles.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            yield put(usersAddEditSlice.actions.setFormState({
                ...user as any,
                username: user.email,
                roleName: resp.data[0].toUpperCase(),
            }))
            yield put(usersAddEditSlice.actions.setState('READY'));
        }
        catch(e: any){
            toast.error('Error loading user', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[UsersAddEditSagas:loadUser] The following exception has occurred`, e);
        }
    }

    private static* createUser(action: ReturnType<typeof createUser>) {
            const { payload } = action;
            const { navigate, ...req } = payload;
            try {
                const config: IConfiguration = yield select(selectConfig);
                const idToken: string = yield select(selectIdToken);
                
                var client = new VerkadaApi(config.apiBaseUrl, idToken);
    
                const resp: AxiosResponse<any> = yield call({
                    context: client,
                    fn: client.register,
                }, req as ApiRegisterRequest);

                if(resp.status === 401)
                {
                    yield put(unauthorized(undefined));
                    return;
                }
    
                if(resp.status !== 201){
                    yield toast.error('An error has occurred creating new user.', { style: {
                        fontWeight: 500,
                        fontSize: '20px',                
                    }});
    
                    return;
                }

                if(req.roleName !== 'REP') {
                const addToRoleResp: AxiosResponse<any> = yield call({
                    context: client,
                    fn: client.addUserToRole,
                }, req.username!, req.roleName!);

                if(addToRoleResp.status === 401)
                {
                    yield put(unauthorized(undefined));
                    return;
                }

                if(addToRoleResp.status !== 200){
                    yield toast.error('An error has occurred creating new user.', { style: {
                        fontWeight: 500,
                        fontSize: '20px',                
                    }});
    
                    return;
                }
            }
    
                yield toast.success('User created.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});
    
                navigate("/users");
            }
            catch(e: any) {
                console.log(`[UsersAddEditSagas:createUser] the following exception has occurred`, e);
            }
        }

    private static* updateUser(action: ReturnType<typeof updateUser>) {
        const { payload } = action;
        const { navigate, ...req } = payload;
        try {
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);

            const resp: AxiosResponse<any> = yield call({
                context: client,
                fn: client.updateUser,
            }, req as ApiUpdateUserProfile);

            if(resp.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(resp.status !== 200){
                yield toast.error('An error has occurred updating user.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            const userRolesResp: AxiosResponse<string[]> = yield call({
                context: client,
                fn: client.getUserRoles,
            }, req.username!);

            if(userRolesResp.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(userRolesResp.status !== 200){
                yield toast.error('An error has occurred loading user roles.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            const userRoles = userRolesResp.data;
            for(const userRole of userRoles){
                const userRoleRemoveResp: AxiosResponse<any> = yield call({
                    context: client,
                    fn: client.removeUserFromRole,
                },
                    req.username!,
                    userRole,
                )
            }

            const addToRoleResp: AxiosResponse<any> = yield call({
                context: client,
                fn: client.addUserToRole,
            }, req.username!, req.roleName!);

            if(addToRoleResp.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(addToRoleResp.status !== 200){
                yield toast.error('An error has occurred updating user.', { style: {
                    fontWeight: 500,
                    fontSize: '20px',                
                }});

                return;
            }

            yield toast.success('User Updated', { style: {
                fontWeight: 500,
                fontSize: '20px',                
            }});

            navigate("/users");
        }
        catch(e: any) {
            console.log(`[UsersAddEditSagas:updateUser] the following exception has occurred`, e);
        }       
    }

    private static* loadRoles(action: ReturnType<typeof loadRoles>) {
        const { payload } = action;
        try {
            yield put(usersAddEditSlice.actions.setState('BUSY'));
            const config: IConfiguration = yield select(selectConfig);
            const idToken: string = yield select(selectIdToken);
            
            var client = new VerkadaApi(config.apiBaseUrl, idToken);
            const rolesResponse: AxiosResponse<any> = yield call(
                {
                    context: client,
                    fn: client.getRoles
                },
            );
            
            if(rolesResponse.status === 401)
            {
                yield put(unauthorized(undefined));
                return;
            }

            if(rolesResponse.status !== 200)
                throw new Error("Error communicating with Verkada Api");

            yield put(usersAddEditSlice.actions.setRoles(rolesResponse.data));
        }
        catch(e: any){
            toast.error('Error loading roles', { style: {
                fontWeight: 500,
                fontSize: '20px',
            }});
            console.log(`[UsersAddEditSagas:loadRoles] The following exception has occurred`, e);
        }   
    }

    private static* loadFeature(action: ReturnType<typeof loadFeature>){
        const { payload } = action;
        try {

        }
        catch(e: any) {
            console.log(`[UsersAddEditSagas:loadFeature] the following exception has occurred`, e);
        }
    }
}