import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import authSlice, { selectIsAuthenticated, sessionExpiration } from '../auth/slice';
import { loadProducts } from '../quotes/catalog/slice';
import { loadConfig } from './config';
import sharedSlice, { loadApp } from './slice';
import { IConfiguration } from './types';

export abstract class SharedSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadApp.type, SharedSagas.loadApp),
            ]);
        }
        catch(e: any){
            console.log(`[SharedSagas:run] the following exception has occurred`, e);            
        }
    }

    private static *loadApp(action: ReturnType<typeof loadApp>) {
        try {
            yield put(sharedSlice.actions.setState('BUSY'));
            
            //Load Config
            const config: IConfiguration = yield call(loadConfig);
            yield put(sharedSlice.actions.setConfig(config));

            //Try and authenticate with local storage information
            yield put(authSlice.actions.rehydrateAuth());

            //Set token expiration redirect
            yield put(sessionExpiration());

            //Go ahead and load our product catalog
            const isAuthorized: boolean = yield select(selectIsAuthenticated);
            if(isAuthorized) {
                yield put(loadProducts());
            }
            yield put(sharedSlice.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[SharedSagas:loadApp] the following exception has occurred`, e);
        }
    }
}