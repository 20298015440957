import { takeLatest, all, delay, put, select, call } from 'redux-saga/effects';
import catalogSlice, { addToQuote, loadProducts, selectCatalogProducts } from './slice';
import data from './data/products.json';
import { CatalogProduct } from './types';
import { IConfiguration } from '../../shared/types';
import { selectConfig } from '../../shared/slice';
import { VerkadaApi } from '../../api/verkada';
import { AxiosResponse } from 'axios';
import { ApiQuote, ApiQuoteProductItem } from '../../api/types';
import { QuoteFormState } from '../add-edit/types';
import addEditQuoteSlice, { selectQuoteFormstate } from '../add-edit/slice';
import { createId } from '../../shared/utils';
import { selectIdToken, unauthorized } from '../../auth/slice';

export abstract class QuoteCatalogSagas {
    public static *run(){
        try {
            yield all([
                takeLatest(loadProducts.toString(), QuoteCatalogSagas.loadProducts),
                takeLatest(addToQuote, QuoteCatalogSagas.addCartToQuote),
            ]);
        }
        catch(e: any){
            console.log(`[QuoteCatalogSagas:run] The following exception has occurred`, e);
        }
    }

    private static *addCartToQuote(action: ReturnType<typeof addToQuote>) {
        const { payload: { navigate, cart }} = action;
        try {
            yield put(catalogSlice.actions.setState('BUSY'));
            const formState: QuoteFormState = yield select(selectQuoteFormstate);
            const { lines } = formState;

            const catalogProducts: ApiQuoteProductItem[] = yield select(selectCatalogProducts);
            const updatedLines = Object.entries(cart).map(([sku, qty]) => {
                const existingLine = lines.find(l => l.partNum === sku);
                if(existingLine) {
                    return ({
                        ...existingLine,
                        qty: (existingLine.qty ?? 1) + qty
                    });
                }

                const catalogItem: ApiQuoteProductItem | undefined = catalogProducts.find(x => x.sku === sku);
                if(catalogItem === undefined)
                    throw new Error(`Could not find sku ${sku} in Product Catalog`);

                return ({
                    uuid: createId(),
                    ...catalogItem,
                    partNum: catalogItem.sku,
                    partDesc: catalogItem.description,
                    qty: qty,
                    price: catalogItem.msrp,
                });
            });

            navigate(`/quotes/create`, {
                state : { generateQuoteFormState: {
                    ...formState,
                    lines: [
                        ...lines.filter(x => x.qty !== undefined && updatedLines.find(ul => ul.partNum === x.partNum) === undefined),
                        ...updatedLines,
                        {
                            uuid: createId(),
                        }
                    ]
                }
                }
            });
        }
        catch(e: any){
            console.log(`[QuoteCatalogSagas:addCartToQuote] The following exception has occurred`, e);
        }
        finally {
            yield put(catalogSlice.actions.setState('READY'));
        }
    }

    private static *loadProducts(action: ReturnType<typeof loadProducts>){
        try {
            yield put(catalogSlice.actions.setState('BUSY'));
            yield put(catalogSlice.actions.rehydrateProducts());
            const productsCache: ApiQuoteProductItem[] = yield select(selectCatalogProducts);
            if(!productsCache || productsCache.length === 0) {
                const config: IConfiguration = yield select(selectConfig);
                const idToken: string = yield select(selectIdToken);
            
                var client = new VerkadaApi(config.apiBaseUrl, idToken);
                const productsResponse: AxiosResponse<ApiQuoteProductItem[]> = yield call({ context: client, fn: client.getProducts });
                
                if(productsResponse.status === 401)
                {
                    yield put(unauthorized(undefined));
                    return;
                }

                if(productsResponse.status !== 200)
                    throw new Error("Error communicating with Verkada Api");
                
                /*
                Because of how the api is designed I need to call the "details" 
                for each SKU I get back from the getProducts call. This needs to be refactored
                as it hangs the ajax for quite a bit.
                */

                const { data: products } = productsResponse;
                /*
                const response: ApiQuoteProductItem[] = yield all(productSKUs.map(sku => call(QuoteCatalogSagas.loadProduct, sku)));
                const products = response.filter(x => x !== undefined);
                */
                yield put(catalogSlice.actions.setProducts(products));
            }

            yield put(catalogSlice.actions.setState('READY'));
        }
        catch(e: any){
            console.log(`[QuoteCatalogSagas:loadProducts] The following exception has occurred`, e);
        }
    }
}